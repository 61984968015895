.scroll-down .inspiratie__filterbalk{
	top:0px;
	@include breakpoint(large){
		top:0px;
		
	}
}
.scroll-down .nieuws__filterbalk{
	top:0px;
	@include breakpoint(large){
		top:0px;
		
	}
}
.scroll-up .inspiratie__filterbalk, .scroll-submenu .inspiratie__filterbalk{
	top:45px;
	@include breakpoint(large){
		top: 100px;
		
	}
}
.scroll-up .nieuws__filterbalk, .scroll-submenu .nieuws__filterbalk{
	top:45px;
	@include breakpoint(large){
		top: 70px;
	}
}
.singlecol.nieuws{
	max-width:1336px;
	margin: 0 auto;
}
.singlecol.inspiratie{
	padding-bottom:0px;
}

#pro-news-logo{
	top:2px;
}
.header__logo-mobile.pro-news-logo-mobile{
	height:unset!important;
}
#nieuws{
	max-width: 100rem;

	.nieuws_blogrij{
		display:grid;
		grid-template-columns: 1fr;
		margin-top:0px;
		width:100%;
		.trainingen-grid__item{
			margin:20px;
			border-radius: 8px;
		}
		@include breakpoint(large up){
			grid-template-columns: 1fr 1fr 1fr !important;
			padding-left: 20px;
			padding-right: 20px;
			.inspiratie-video-image-overlag .vid-icon img{
				width:55px;
			}

			.training-grid-block:hover .training-grid-block__title {
				color: $color-business-blue;
			}
		}
	}
	@include breakpoint(large up){
		.nieuws_blogrij .label, .banner_nieuws .label{
			padding:6px 14px; 
			font-weight:500;
			text-transform: capitalize;
	
		}
	}
}
#inspiration{
	max-width: 100rem;

	.inspiration-list{
		display:flex;
		flex-direction: column;
		margin-top:30px;
		width:100%;
		@include breakpoint(large){
			margin-top: 0px;
		}

		.trainingen-grid__item{
			display: flex;
			flex-direction: row-reverse;
			margin: 40px 20px 0 20px;
			padding-bottom: 20px;

			border-radius: 8px;
		}
	}

}
.nieuws__filterbalk{
	position: sticky;
	top:0px;
	padding-bottom:10px;
	border-bottom:1px solid #DBE1E8;
	@include breakpoint(large){
    	top: 70px;
		border-bottom:0px;
		padding-bottom:0px;
		max-width: 1600px;
    	margin: 0 auto;
	}
	background-color: white;
	z-index: 10;
	transition: all 400ms;
}
.inspiratie__filterbalk{
	position: sticky;
	top:0px;
	padding-bottom:10px;
	border-bottom:1px solid #DBE1E8;
	@include breakpoint(large){
    	top: 70px;
		border-bottom:0px;
		padding-bottom:0px;
		max-width: 1600px;
    	margin: 0 auto;
	}
	background-color: white;
	z-index: 10;
	transition: all 400ms;
}
.filterbalk{
	width: 100%;
	display:flex;
	//grid-template-columns: 6fr 1fr 1fr 1fr;
	margin-top:10px;
	padding:0px 20px;
	@include breakpoint(large){
		width:unset;
		padding:0px;
		padding-bottom:10px;
		border-bottom:1px solid #DBE1E8;
	}
	//grid-row-gap: 10px;
	column-gap: 5px;
	.categoriebalknieuws{
		//display: grid;
		//justify-items: flex-start;
		align-items: center;
		min-width: 170px;

		select{
			width:100%;
			border: 1px solid $color-business-blue;
			height:3rem;
			border-radius: 6px;
			line-height: 1.2rem;
			font-weight: 500;
			color: $color-business-blue;
			background-position: right -0.5rem center;
			background-size: 14px 10px;
			margin:0;
			font-size:14px;
			padding: 0.5rem 1rem 0.5rem 1rem; 
			background-image: url('/images/iconmonstr-caret-down-filled-32.png');
			background-size: contain;
			backdrop-filter:unset;
			&:focus{
				box-shadow: none;
			}
			//backdrop-filter: invert(17%) sepia(11%) saturate(5511%) hue-rotate(207deg) brightness(88%) contrast(104%);
		}
	}

	.typenieuws{
		display: flex;
		margin:auto;
		width: 100%;
		margin-bottom: -11px;
		overflow: scroll;
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
    	scrollbar-width: none;  /* Firefox */
		position: relative;
		grid-column: span 3;
		&::-webkit-scrollbar { 
			width: 0;  /* Remove scrollbar space */
   			background: transparent;  /* Optional: just make scrollbar invisible */
		}

		.verschillendetype {
			padding: 9px 10px; /* Adjust padding to reduce button size */
			padding-bottom:19px;
			background-color: transparent; /* Remove background */
			color: #888AAA; /* Muted purple color */
			text-decoration: none;
			font-weight: 500; /* Default font weight */
			font-size: 14px;
			border: none; /* Remove borders */
			white-space: nowrap;
			transition: color 300ms, border-bottom 300ms;

			&:hover {
				color: #585a7f; /* Hover color: dark blue */
				font-weight: 600; /* Increase font weight on hover */
				padding-left:9px;
				padding-right:9.5px;
			}
		}

		.verschillendetype_selected {
			padding-left:9px;
			padding-right:9.5px;
			color: #585a7f; /* Active state color */
			font-weight: 600; /* Bold for active button */
			border-bottom: 1px solid #1E225C; /* Add bottom underline */
		}

	}
	.searchbarnieuws{
		display: grid;
		justify-items: center;
		@include breakpoint(large){
			justify-items: flex-end;
		}
		align-items: center;
		position: relative;
		&.search-selected{
			padding-right: 30px;
			#searchnieuwsoverzicht{
				width:90%;
			}
			#searchiconinspiratie{
				right:19%;
			}
		}
		#searchnieuwsoverzicht{
			margin:0;
			width: unset;
			border:1px solid $color-business-blue;
			border-radius: 8px;
			padding-left: 1rem;
			padding-right: 2rem;

			&::placeholder{
				font-size:14px;
				color: $color-business-blue;
				opacity: 1;
			}
		}
	}

	.mobile-icon-box{
		border: 1px solid #1e225d;
		border-radius: 2px;
		display: grid;
		align-items: center;
		justify-items: center;
	}
}
#searchiconinspiratie{
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjEuMTcyIDI0bC03LjM4Ny03LjM4N2MtMS4zODguODc0LTMuMDI0IDEuMzg3LTQuNzg1IDEuMzg3LTQuOTcxIDAtOS00LjAyOS05LTlzNC4wMjktOSA5LTkgOSA0LjAyOSA5IDljMCAxLjc2MS0uNTE0IDMuMzk4LTEuMzg3IDQuNzg1bDcuMzg3IDcuMzg3LTIuODI4IDIuODI4em0tMTIuMTcyLThjMy44NTkgMCA3LTMuMTQgNy03cy0zLjE0MS03LTctNy03IDMuMTQtNyA3IDMuMTQxIDcgNyA3eiIvPjwvc3ZnPg==");
	background-position: right -0.1rem center;
	background-repeat: no-repeat;
	background-size: 20px 15px;
	width:20px;
	border:0;
	background-color:transparent;
	position: absolute;
	right: 12%;
	@include breakpoint(large){
		right:7%;
	}
	cursor: pointer;
}
.geenpostsgevonden{
	font-size: 25px;
	font-weight: 600;
	text-align: center;
	margin: auto;
	margin-top: 100px;
}
.banner.row{
	max-width: 100rem;
}
.post-highlight{
	border-bottom:0px; 
	display: grid;
	grid-template-columns: 5.5fr 4.5fr; // Adjusting the width ratio between image and text
	@include breakpoint(xlarge){
		grid-template-columns: 7fr 4fr;
	}
	margin: 0 40px 0 40px;
	padding-top: 50px;
	padding-bottom: 20px;


	&:hover {
		cursor: pointer;
	}
	&__image{
		width: 100%;
		min-height: 398px; // Slightly increased height for better proportion
		max-height: 398px;
		border-radius: 4px 20px 20px 20px;
		border:1px solid #DBE1E8;
		object-fit: cover;
		img{
			border: none!important;
			border-radius: 4px 20px 20px 20px;
			//border: 1px solid #DBE1E8;
			height:100%;
			width:100%;
			object-fit: cover;
		}
	}
	&__content{
		align-self: flex-start; // Align text to the top
		margin-left: 40px; // Reduced margin
		padding-top:15px;
		color: #333; // Default text color

		.nieuws_banner_type {
			margin-top: 10px;
			color: red; // Red for the type and extra info

			div {
				margin-right: 10px;
				font-size: 16px;
				font-weight: bold;
			}
		}
	}
	&__title{
		margin-top: 15px;
		font-size: 20px; // Slightly smaller font
		font-weight: 700;
		@include header-text;
		color: #0A246A;; // Blue for title
		margin-bottom:23px;
	}
	&__text{
		margin-top: 23px;
		font-size: 15px; // Smaller description text
		line-height: 1.7;
		@include inspiration-body-text;
		max-width: none; // Remove width restriction
		color: rgba(22,25,78,0.8); // Lighter blue
	}
	&__new-marker {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 10px;
		font-size: 13px;
		font-weight: 400;
		color: #4A9030;

		.relative_time {
			font-size: 13px;
			font-weight: 300;
			color: #4A9030;
		}
	}
}

.banner_nieuws{
	display:grid;
	grid-template-columns: 5fr 5fr;
	margin-top:40px;
	margin-bottom: 40px;
	margin-left:40px;
	margin-right:40px;
	&:hover{
		cursor: pointer;
		.banner_title_nieuws{
			//color:$color-orange;
		}
	}

	.banner_img_nieuws{
		width:100%;
		height:482px;
		border-radius: 10px;
		object-fit: cover;
	}
	.banner_tekst_nieuws{
		align-self: center;
		margin-left:70px;

		.nieuws_banner_type{
			//margin-top:50px;

			div{
				margin-right:20px;
				font-size:16px;
			}
		}
		.banner_title_nieuws{
			margin-top:20px;
			font-size:39px;
			font-weight: 800;
			max-width: 80%;
			max-height: 100%;
		}
	}
	.banner_nieuws_leesmeer{
		margin-top:20px;
	}
	.banner_nieuws_tekst{
		max-width: 60%;
		max-height: 100%;
		font-size: 16px;
	}
	.cf-button--orange-link .cf-button__text{
		font-weight: 600;
	}
	.banner_new_marker {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 10px;
		font-size: 13px;
		font-weight: 400;
		color: #4A9030;

		.relative_time {
			font-size: 13px;
			font-weight: 300;
			color: #4A9030;
		}
	}
}

.training-grid-block-blog-mobile{
	display: flex!important;
	flex-direction: row;
	gap:10px;
	margin-top: 20px !important;
	box-shadow: none !important;
	padding:0px!important;

	.inspiration-listing__author{
		display: flex;
		gap:10px;
		align-items: center;
		&__text{
			font-size:14px;
			@include header-text;
			span{
				font-size:14px;
				@include header-text;
			}
		}
	}
	.inspiration-listing__meta{
		
	}
	.training-grid-block__content{
		height:unset!important;
		min-height:unset!important;
		flex-direction: row;
	}
	.training-grid-block__content__text{
		margin-left:0px!important;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		height: 100% !important;
    	justify-content: space-between;
	}
	.training-grid-block__content__infotext{
		padding: 0px!important;
		flex-grow: 1;
	}
	.training-grid-block__image{
		//border-radius:8px;
		width:120px;
		height:80px;
		@include breakpoint(medium){
			width:160px;
			height:100px;

		}
		.training-grid-block__image__overlay{
			//border-radius:8px;
		}
	}

	.mobile-img-grid-block{
		height:80px !important;
		min-width:120px!important;
		@include breakpoint(medium){
			max-width:unset!important;
			width:160px!important;
			height:100px!important;
		}
		//margin-left: 10px;
		border-radius: 0px 8px 8px 8px!important;
	}
	.inspiratie-video-image-overlag .vid-icon img{
		width:40px;
	}
	p.training-grid-block__title{
		font-weight: 600 !important;
		@include header-text;
		font-size:16px;
		width: 90%;
		margin-bottom:0px;
		@include breakpoint(medium){
			font-weight:700;
			font-size:18px;
		}
	}
}
.nieuws-blogrijmobile{
	padding:0px 20px;
	width:100%;
}

#testing123{
	display: none;
}
@include breakpoint(large up){
	.nieuws_blogrij{
		padding-left: 20px;
    	padding-right: 20px;
		.training-grid-block__image, .training-grid-block__image__overlay, .training-grid-block__image img{
			//border-radius:8px 8px 0px 0px;
		}
		.inspiratie-video-image-overlag .vid-icon img{
			width:55px;
		}

		.training-grid-block:hover .training-grid-block__title {
			color: $color-business-blue;
		}
	}
	.nieuws_blogrij .label, .banner_nieuws .label{
		padding:6px 14px; 
		font-weight:500;
		text-transform: capitalize;

	}
	.filterbalk{
		//grid-template-columns: 1fr 3fr 0fr 1fr;
		//grid-row-gap: 0px;
		margin-top: 40px;
		//margin-bottom: 10px;
		//padding-left:60px;
		//padding-right:60px;
		margin-left:40px;
		margin-right:40px;
		column-gap: 0px;
		.categoriebalknieuws{
			justify-items: flex-start;
			margin-right:40px;
			select{
				//width:80%;
				height: initial;
			}
		}

		.typenieuws{
			overflow: scroll;
			position: relative;
			grid-column: inherit;
			
		}
	}

}
.mobile-searchbar{
	height: 20%;
	width: 100%;
}

.scroll-header .sidebar, .scroll-submenu .sidebar, .scroll-up .sidebar {
	top:145px;
}
.scroll-down .sidebar  {
	top:45px;
}

.doublecol-container {
	display: flex;
	flex-wrap: nowrap;
	flex-direction: row;
	max-width:1450px;
	margin:0 auto;

	.main-content {
		width: 100%;
		flex-grow: 1;
		@include breakpoint(xlarge){
			padding-right:10px;
		}
	}

	#sidebar-content {
		// add gray line to separate sidebar from main content
		border-left: 1px solid #DBE1E8;
		flex-grow: 1;
		//margin-bottom: 127px;
		padding:20px;
		@include breakpoint(large){
			padding:20px 60px;
		}
		@include breakpoint(xlarge){
			max-width:400px;
			padding:30px 40px;
			padding-left:50px;
		}
		box-shadow: none;
		border-radius: 0px;
		background-color: #FFF;

		.sidebar{
			background-color: #fff;
			padding: 0px;
			border-radius: 0px;
			box-shadow: none;
            // Make the sidebar sticky
            position: sticky;
            //top: 20px; // Adjust this value to control how far from the top it stays
            height: fit-content; // Ensure the sidebar doesn't overflow


        }
	}

	@include breakpoint(large down) {
		flex-direction: column; // Stack elements vertically
		.main-content, .sidebar {
			width: 100%; // Make both take full width
			border-left: none; // Remove the border-left from the sidebar
		}
	}

	.post-type {
		font-size: 12px;
		font-weight: normal;
		color: #888AAA;
		display: flex;
		gap:15px;
	}

	.rotate-button {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background-color: white; /* Button background */
		color:#888AAA; /* Arrow color */
		padding-top: 7px;
		border-radius: 50%; /* Makes the button circular */
		width: 35px; /* Fixed width for a circle */
		height: 35px; /* Fixed height for a circle */
		font-size: 18px; /* Size of the arrow */
		font-weight: bold; /* Make arrow bold */
		cursor: pointer; /* Indicate clickability */
	}

	.rotate-button:hover {
		//border-bottom: 2px solid #C0C0C0; /* Darker grey border on hover */
		color: #585a7f /* Darker arrow color on hover */
	}
}

.post-block {
	border-bottom: 0px;
	box-shadow: none;
	margin-top:0px!important;
	padding:20px!important;

	.training-grid-block__content__infotext{
		padding-top:0px;
		padding-left:0px;
		height:100%;
		display: flex;
		flex-direction: column;

	}
}
.post-row-block-divider{
	background-color: #DBE1E8;
	height: 1px;
	margin:20px 20px;
	@include breakpoint(large){
		margin:20px 40px;
	}

}
.post-row {
	border-bottom: 0px;
	box-shadow: none;
	margin-top:0px;
	display: flex;
	padding: 0px;
    flex-direction: column;
    margin: 0px 20px 0 20px;
    border-radius: 8px;
	text-decoration: none;
	transition: all 400ms;
	gap:10px;
	&:hover{
		.post-row__title{
			color: rgb(255, 153, 0);
		}
		.post-row__image_overlay{
			background-color: rgba(32, 42, 54, 0.5);
		}
	}
	@include breakpoint(large){
		padding:20px;

	}


	&__title {
		font-weight: 600;
		font-size: 16px;
		width: 90%;
		margin-bottom: 0px;
		line-height: 1.2;
		transition: all 400ms;
		@include breakpoint(medium){
			font-size: 18px;
			width:100%;
			line-height: 1.5;
		}
		@include breakpoint(large){
			font-size: 20px;
			font-weight: 700;
			width:100%;
		}
	}

	&__content {

		display: flex;
		flex-direction: row;
		&__container{

			width: 100%;
			height:100%;
			display: flex;
			flex-direction: column;
			height: 53%;
			padding: 0px;
			flex-grow: 1;
			@include breakpoint(medium){
				padding-right:20px;
			}
			@include breakpoint(large){
				padding-right:50px;
			}
		}
	}

	&__image {
		width: 120px;
    	height: 80px;
		border-radius: 0px 16px 16px 16px;
		position: relative;
		@include breakpoint(medium){
			width: 160px;
			height: 100px;
		}
		@include breakpoint(large){
			width: 25%;
			min-width: 290px;
			height: 210px;
		}
		& > img{
			max-width: unset;
			width: 120px;
			height: 80px;
			border: 1px solid #DBE1E8;
			border-radius: 0px 16px 16px 16px;
			object-fit: cover;
			@include breakpoint(medium){
				width: 160px;
				height: 100px;
			}
			@include breakpoint(large){
				width: 100%;
				height: 210px;
			}
		}

	}

	&__image_overlay {
		width: 100%;
		position: absolute;
		height: 100%;
		opacity: 1;
		border-radius: 0px 16px 16px 16px;
		display: grid;
		justify-content: center;
		align-content: center;
		transition: all 400ms;
		.vid-icon{
			padding: 10px!important;
			@include breakpoint(large){
				padding: 20px!important;
			}
		}
		.vid-icon img {
			width: 30px;
			@include breakpoint(large){
				width: 55px;
			}
		}
	}
	&__extralabel{
		position: absolute;
		display: flex;
		align-items: center;
		top: 15px;
		left: 30px;
		color: white;
		opacity: 0;
		font-size: 16px;
		font-weight: bold;
		text-shadow: 0px 0px 4px #000000;
	}
	&__infolabel{
		position: absolute;
		bottom:15px;
		display: flex;
		align-items: center;
		gap: 20px;
		left: 20px;
		color: white;
		font-size: 12px;
		font-weight: bold;
		&__type{
			background-color: #1e225d;
			padding: 5px 13px;
			border-radius: 6px;
			text-transform: capitalize;
		}
	}
	&__text_container {
		height: 100%;
		flex-grow: 1;
		max-width: 750px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap:12px;
		text-overflow: ellipsis;
		@include breakpoint(medium){
		height: auto;
		}
	}

	&__text {
		font-size: 15px;
		text-overflow: ellipsis;
		font: normal normal normal 15px/26px Gelasio;
		@include inspiration-body-text;
		height: 4em;
		margin-bottom:0px;
	}

	&__author {
		font-size: 14px;
		font-weight: 500;
		color: $color-business-blue;
		display: flex;
		gap:10px;
		align-items: center;
		@include breakpoint(large){
			margin-bottom: 16px;
		}
		&__text{

		}
	}

	&__author-icon {
		width: 2rem;
		height: 2rem;
		border-radius: 100%;
		object-fit: contain;
		border:1px solid #DBE1E8;
	}
	&__content{
	}
	&__meta {
		font-size: 14px;
		font-weight: 400;
		color: #888AAA;
		display: flex;
		@include header-text;
		gap:7px;
		align-items: center;

		@include breakpoint(medium down) {
			//padding-right: 5px;
			font-size: 10px;
		}
	}

	&__meta-container {
		display: flex;
		align-items: center;
		margin-top: 0px;
		gap:30px;

		@include breakpoint(medium down) {
			flex-direction: row;
			margin: 0 !important;
		}
	}

	&__meta-img {
		width: 20px;
		height: 20px;
	}
	&__svg {
		width: 20px;
		height: 20px;
		// filter to light gray
		filter: invert(65%) sepia(10%) saturate(684%) hue-rotate(198deg) brightness(87%) contrast(89%);
	}
}

.training-row-block {
	border-bottom: 0px;
	box-shadow: none;
	margin-top:0px;
	display: flex;
	padding: 0px;
    flex-direction: row;
    margin: 0px 20px 0 20px;
    border-radius: 8px;
	text-decoration: none;
	transition: all 400ms;
	gap:20px;
	&:hover{
		.training-row-block__title{
			color: rgb(255, 153, 0);
		}
		.training-row-block__image_overlay{
			background-color: rgba(32, 42, 54, 0.5);
		}
	}
	@include breakpoint(large){
		padding:20px;
		&:hover{
			box-shadow: 0px 10px 30px 0px rgba(157, 171, 192, 0.35);
			border-color: rgb(218.7615384615, 221.35, 223.9384615385);
		}

	}


	&__title {
		font-weight: 600;
		font-size: 16px;
		width: 90%;
		margin-bottom: 16px;
		line-height: 1.2;
		transition: all 400ms;
		@include breakpoint(medium){
			font-size: 18px;
			width:100%;
			line-height: 1.5;
		}
		@include breakpoint(large){
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 16px;
			width:100%;
		}
	}

	&__content {

		display: flex;
		flex-direction: column;
		gap:16px;
		flex-grow:1;
		&__container{

			width: 100%;
			height:100%;
			display: flex;
			flex-direction: column;
			height: 53%;
			padding: 0px;
			flex-grow: 1;
			@include breakpoint(medium){
				padding-right:20px;
			}
		}
	}

	&__image {
		width: 120px;
    	height: 80px;
		border-radius: 0px 12px 12px 12px;
		position: relative;
		@include breakpoint(medium){
			width: 160px;
			height: 100px;
		}
		@include breakpoint(large){
			width: 25%;
			min-width: 290px;
			height: 210px;
		}
		& > img{
			max-width: unset;
			width: 120px;
			height: 80px;
			border: 1px solid #DBE1E8;
			border-radius: 0px 12px 12px 12px;
			object-fit: cover;
			@include breakpoint(medium){
				width: 160px;
				height: 100px;
			}
			@include breakpoint(large){
				width: 100%;
				height: 210px;
			}
		}

	}

	&__image_overlay {
		width: 100%;
		position: absolute;
		height: 100%;
		opacity: 1;
		border-radius: 0px 12px 12px 12px;
		display: grid;
		justify-content: center;
		align-content: center;
		transition: all 400ms;
		.vid-icon{
			padding: 10px!important;
			@include breakpoint(large){
				padding: 20px!important;
			}
		}
		.vid-icon img {
			width: 30px;
			@include breakpoint(large){
				width: 55px;
			}
		}
	}
	&__extralabel{
		position: absolute;
		display: flex;
		align-items: center;
		top: 15px;
		left: 30px;
		color: white;
		opacity: 0;
		font-size: 16px;
		font-weight: bold;
		text-shadow: 0px 0px 4px #000000;
	}
	&__infolabel{
		position: absolute;
		bottom:15px;
		display: flex;
		align-items: center;
		gap: 20px;
		left: 20px;
		color: white;
		font-size: 12px;
		font-weight: bold;
		&__type{
			background-color: #1e225d;
			padding: 5px 13px;
			border-radius: 6px;
			text-transform: capitalize;
		}
	}
	&__text_container {
		height: 100%;
		flex-grow: 1;
		max-width: 750px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap:20px;
		text-overflow: ellipsis;
		@include breakpoint(medium){
		height: auto;
		}
	}

	&__text {
		font-size: 16px;
		text-overflow: ellipsis;
		font: normal normal normal 16px/26px Gelasio;
		@include inspiration-body-text;
		height: auto;
	}

	&__author {
		font-size: 12px;
		font-weight: 500;
		color: $color-business-blue;
		display: flex;
		gap:10px;
		align-items: center;
		@include breakpoint(medium) {
			font-size: 14px;
		}
		@include breakpoint(large){
		}
		&__text{
			@include header-text;
		}
	}

	&__author-icon {
		width: 2rem;
		height: 2rem;
		border-radius: 100%;
		object-fit: contain;
		border:1px solid #DBE1E8;
	}

	&__meta {
		&__container{
			display: flex;
			align-items: center;
			gap:20px;
			@include breakpoint(medium) {
				gap:40px;
			}
		}
		&__item{
			display: flex;
			gap:7px;
			align-items: center;
			&__icon{
				width:16px;
				height:16px;
			}
			&__text{
				font-size: 12px;
				font-weight: 500;
				color: $color-business-blue;
				@include header-text;
				@include breakpoint(medium) {
					font-size: 14px;
				}
			}
		}
	}

	&__meta-container {
		display: flex;
		align-items: center;
		margin-top: 0px;
		gap:30px;

		@include breakpoint(medium down) {
			flex-direction: row;
			margin: 0 !important;
		}
	}

	&__meta-img {
		width: 20px;
		height: 20px;
	}
	&__svg {
		width: 20px;
		height: 20px;
		// filter to light gray
		filter: invert(65%) sepia(10%) saturate(684%) hue-rotate(198deg) brightness(87%) contrast(89%);
	}
	&__favorite {
		height:30px;
		background-color:white;
		position:absolute;
		right:0px;
		top:0px;
		z-index:4;
		border-radius: 0px 0px 0px 6px;
		display:flex;
		justify-content: center;
		align-items: center;
		padding:0 9px;
		&::after{
			content:"Bewaar";
			color:$color-business-blue;
			font-size:14px;
			font-family: 'lexend', sans-serif;
			padding-left:2px;
		}
		&.selected{
		   //border: 1px solid #FF4F57;
			&::after{
				content:"Bewaard";
			}
			&.collection{
				padding:5px 5px 5px 5px;
			  &::after{
				  content:"Verwijder";
			  }
			}
			img.selected{
				filter: invert(36%) sepia(79%) saturate(1372%) hue-rotate(328deg) brightness(112%) contrast(107%);
				display:block;
				margin:5px!important;
				margin-left:0px !important;
				height:50%;
				width:unset!important;
				//padding-left:2px;
				&.trashcan{
					height:80%;
				}
			}
			img.notselected{
				display:none;
				margin:5px!important;
				margin-left:0px !important;
				height:50%;
				width:unset!important;
				//padding-left:2px;
			}
			&.collection{
				img.selected{
					filter: invert(9%) sepia(69%) saturate(2738%) hue-rotate(228deg) brightness(99%) contrast(93%);
				}
			}
		}
		&.notselected{
			img.selected{
				display:none;
				margin:5px!important;
				margin-left:0px !important;
				height:50%;
				width:unset!important;
				//padding-left:2px;
			}
			img.notselected{
				filter: invert(9%) sepia(69%) saturate(2738%) hue-rotate(228deg) brightness(99%) contrast(93%);
				display:block;
				margin:5px!important;
				margin-left:0px !important;
				height:50%;
				width:unset!important;
				//padding-left:2px;
			}
		}
		&:hover{
			cursor:pointer;
			&.notselected{
			  img.notselected{
				  filter: invert(36%) sepia(79%) saturate(1372%) hue-rotate(328deg) brightness(112%) contrast(107%);
				  transition:(all 400ms);
			  }
		  }
		}
	}
	&__aiproof{
	  	position:absolute;
		top:10px;
		left:15px;
		img{
		//filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(78deg) brightness(109%) contrast(101%) drop-shadow(0px 0px 4px #000);
		//width:18px;
	  		height:60px;
	  	}
	}
	&__status-label{
	  	position:absolute;
		top:10px;
		left:15px;;
		background-color: #f2f8ff;
		padding: 5px 13px;
		border-radius: 6px;
		text-transform: capitalize;
		color: #1e225d;
		font-size: 12px;
		font-weight: bold;
	}
	&__pricelabel{
		position:absolute;
		display:flex;
		align-items:center;
		top:15px;
		left:30px;
		color:white;
		opacity:0;
		font-size:16px;
		font-weight:bold;
		text-shadow: 0px 0px 4px #000000;
		&.show{
			opacity:1;
		}
		img{
			filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(78deg) brightness(109%) contrast(101%) drop-shadow(0px 0px 4px #000);
			width:18px;
			height:18px;
			margin-right:7px;
		}
	}
	&__infolabel{
	  	position:absolute;
		display:flex;
		align-items:center;
	  	gap:20px;
		bottom:15px;
		left:20px;
		color:white;
		font-size:12px;
	  	font-weight: 600;
		font-weight:bold;
		&.jobs-demand{
			font-size:11px;
			@include breakpoint(medium){
				font-size:12px;
			}
		}
		&__type{
			background-color:$color-business-blue;
			padding:5px 13px;
			border-radius: 6px;
			text-transform: capitalize;
		}
		//&.heeftlabel{
		//	left:20px!important;
		//	bottom:50px!important;
		//}
	}
}

@keyframes rotate {
	100% {
	  transform: rotate(360deg);
	}
  }
/* Header Styling */
header {
	background-color: #ffffff;

	.container-inspiratie {
		align-items: center;
		justify-content: space-between;
		background: white;
		width: 100%;
		margin: 0;
		padding: 0 1.5rem;
		display: flex;
		gap:10px;
		height:50px;
		@include breakpoint(medium){
			height: 70px;
			gap:20px;
		}
		@include breakpoint(large){
			height: 90px;
			gap:60px;
		}
	}

	/* Left Section */
	.logo-section {
		display: flex;
		align-items: center;

		img {
			height: 21px;
			object-fit: contain;
			max-width:unset;
			@include breakpoint(medium){
				height: 30px;
			}
			@include breakpoint(large){
				height: 40px;
			}
		}

		span {
			font-size: 0.875rem;
			color: #6b7280; /* Gray */
			margin-left: 10px;
		}
	}

	/* Middle Section */
	.search-bar {
		flex-grow:1;
		.search-form {
    		position: relative;
    		height: 36px;
			max-width:450px;
			@include breakpoint(medium){
				height:43px;
			}
			@include breakpoint(large){
				height:50px;
			}
		}
		input {
			width: 100%;
			padding: 8px 33px 9px 36px;
			border: 1px solid #F7F7F7; /* Light gray */
			color:#1E225C;
			border-radius: 9999px;
			font-size: 14px;
			outline: none;
			background-color: #F7F7F7;
			box-shadow:none;
			margin-bottom:0px;
    		height: 36px;
			@include breakpoint(medium){
				height:43px;
				font-size: 15px;
				padding: 12px 33px 12px 66px;
			}
			@include breakpoint(large){
				padding: 12px 33px 12px 66px;
				font-size: 16px;
				height:50px;
				max-width:450px;
			}
			&::placeholder{
				color:$color-business-blue;
				font-size: 14px;
				@include breakpoint(large){
					font-size:16px;
				}
			}
			&:focus {
				border-color:#1E225C;
				box-shadow: none;
			}
		}
		.search-form__button{
			left: 2px;
			top: 1px;
			@include breakpoint(medium){
				left:5px;
				top: 5px;
			}
			@include breakpoint(large){
				left:20px;
				top: 8px;
			}
		}
		.search-form__clear{
			right: 8px;
			top: 5px;
			width: 25px;
			height: 25px;
			@include breakpoint(medium){
				top: 6px;
				width: 30px;
				height: 30px; 
			}
			@include breakpoint(large){
				right: 11px;
				top: 7.5px;
				width: 35px;
				height: 35px; 
			}
		}
		.search-form__submitbutton{
			position: absolute;
			right: 8px;
			top: 5px;
			width: 25px;
			height: 25px;
			@include breakpoint(medium){
				top: 6px;
				width: 30px;
				height: 30px; 
			}
			@include breakpoint(large){
				right: 11px;
				top: 7.5px;
				width: 35px;
				height: 35px; 
			}
			color: #1e225d;
			img{
			  filter:invert(20%) sepia(9%) saturate(5499%) hue-rotate(194deg) brightness(95%) contrast(106%);
			}
		}
		.search-form__loading{
			position: absolute;
			right: 8px;
			top: 5px;
			width: 25px;
			height: 25px;
			@include breakpoint(medium){
				top: 6px;
				width: 30px;
				height: 30px; 
			}
			@include breakpoint(large){
				right: 11px;
				top: 7.5px;
				width: 35px;
				height: 35px; 
			}
			color: #1e225d;
			animation: rotate 2s linear infinite;
			img{
			  filter:invert(20%) sepia(9%) saturate(5499%) hue-rotate(194deg) brightness(95%) contrast(106%);
			}
		}
	}

	/* Right Section */
	.nav-section {
		display: flex;
		align-items: center;
		gap: 1rem;

		/* Creative Business School Link */
		a.business-school {
			font-size: 16px;
			font-weight: 600;
			color: #888AAA; /* Dark gray */
			text-decoration: none;
			display: flex;
			align-items: center;
			gap:5px;
			transition: color 400ms;
			span{
				padding-bottom:2px;
			}
			.icon{
				transition: filter 400ms;
				transform: rotate(45deg);
				height:20px;
				filter: invert(58%) sepia(19%) saturate(440%) hue-rotate(198deg) brightness(92%) contrast(86%);
			}
			&:hover {
				text-decoration: none;
				color:#1E225C;
				.icon{
					filter: invert(6%) sepia(45%) saturate(6605%) hue-rotate(236deg) brightness(102%) contrast(87%);
				}
			}
		}

		/* Word Member Button */
		a.member-button {
			background-color: #1e3a8a; /* Dark blue */
			color: #ffffff;
			padding: 0.5rem 1rem;
			border-radius: 4px;
			font-size: 0.875rem;
			text-decoration: none;
			font-weight: bold;

			&:hover {
				background-color: #1d4ed8; /* Lighter blue */
			}
		}
		.header__menu-button{
			img{
				width: 24px !important;
    			height: 24px !important;
				max-width:unset;
			}
		}
	}

	/* Purple Gradient Bar */
	.promo-bar {
		background: linear-gradient(to right, #79b9d4, #3f6e9f);
		//background: linear-gradient(to right, #ec4899, #8b5cf6); /* Pink to purple */
		color: #ffffff;
		text-align: center;
		padding: 0.5rem 0;
		@include header-text;
		span {
			font-size: 14px;
			font-weight: 500;
		}

		a {
			font-size: 14px;
			font-weight: 500;
			color: #ffffff;
			text-decoration: underline;
		}
	}

	.promo-bar-post {
		background: linear-gradient(to right, #79b9d4, #3f6e9f); /* Pink to purple */
		color: #ffffff;
		text-align: center;
		padding: 0.5rem 0;
		@include header-text;

		span {
			font-size: 14px;
			font-weight: 500;
		}

		a {
			font-size: 14px;
			font-weight: 500;
			color: #ffffff;
			text-decoration: underline;
		}
	}
}

.header-mobile {
	background-color: white;
	position: sticky;
	top: 0;
	z-index: 1000;

	&__container {
		box-shadow: 0 3px 6px rgba(127, 139, 152, 0.16);
	}

	&__firstrow {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.5rem 1rem;
	}

	#search-mobile-input {
		width: 90%;
		padding: 0.5rem;
		border: 1px solid #ddd;
		border-radius: 4px;
	}

	.header__menu-button img {
		width: 25px;
		height: 25px;
	}
}


.sidebar {
	background-color: #f9f9f9; // Light background for the sidebar
	padding: 20px; // Add padding for spacing
	border-radius: 8px; // Rounded corners
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
	.sidebar-spacer{
		width:100%;
		height:1px;
		background-color: #DBE1E8;
		margin:30px 0px;
	}
	.sidebar-section {
		margin-bottom: 30px; // Space between sections
		@include breakpoint(large){
			max-width:380px;
		}
		.sidebar-title {
			@include header-text;
			font-size: 16px; // Font size for section titles
			font-weight: 500; // Bold text
			margin-bottom: 34px; // Space below the title
			letter-spacing: -0.2px;
			//color: #333; // Dark gray color
			//border-bottom: 2px solid #ddd; // Bottom border to underline
			//padding-bottom: 5px; // Add padding for spacing
		}

		.post-list {
			list-style: none; // Remove default list styling
			padding: 0; // Remove unwanted padding
			margin: 0; // Remove unwanted margins

			.post-item {
				margin-bottom: 34px; // Space between items
				display: flex; // Align thumbnail and title in a row
				align-items: center; // Vertically center items
				.post-link {
					display: flex; // Make the links row-based
					//align-items: center; // Vertically align items
					text-decoration: none; // Remove underline from links
					//color: #000; // Black text for links
					transition: all 0.3s ease; // Add hover transition
					flex-direction: column;
					width:100%;
					&:hover {
						color: #007bff; // Blue color on hover
					}
					.post-author{
						display: flex;
						gap: 9px;
						align-items: center;

						margin-bottom:10px;
						.author-text{
							font-size:12px;
							font-weight:500;
							color:#1E225C;
							@include header-text;
						}
					}
					.post-thumbnail {
						width: 50px; // Thumbnail width
						height: 50px; // Thumbnail height
						border-radius: 6px; // Rounded corners for thumbnails
						object-fit: cover; // Crop and fit image
						margin-right: 10px; // Space between thumbnail and title
						box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Subtle shadow for thumbnails
					}

					.post-title {
						font-size: 15px; // Font size for titles
						font-weight: 500; // Slightly bold text
						line-height: 25px;
						//white-space: nowrap; // Prevent text wrapping
						overflow: hidden; // Hide overflow for long text
						text-overflow: ellipsis; // Add ellipsis for long text
					}
				}
			}
		}

		.sidebar-readmore{
			a {
				color:$color-orange;
				text-decoration: none;
				font-size:13px;
				@include header-text;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
}
.inspiration-detail{
	&__content{
		max-width: 700px;
		margin:0 auto;
		padding: 0 24px;
		&__hoofdstukken{
			margin-top:50px;
		}
		&__text{
			margin-top:50px;
			margin-bottom:50px;
			@include breakpoint(large){
				margin-bottom:100px;
			}
			.page__content{
				h2{
					margin-top:30px;
					margin-bottom:16px;
					font: normal normal bold 24px/34px Lexend;
					line-height: 34px;
					color:#1E225C;
					strong, b, a, span{ font: normal normal bold 24px/34px Lexend;}
				}
				h3{
					margin-top:35px;
					margin-bottom:16px;
					font: normal normal bold 22px/32px Lexend;
					line-height: 32px;
					color:#1E225C;
					strong, b, a, span{ font: normal normal bold 22px/32px Lexend;}
				}
				h4{
					font: normal normal bold 20px/48px Lexend;
					line-height: 32px;
					margin-bottom:16px;
					color:#1E225C;
					strong, b, a, span{ font: normal normal bold 20px/32px Lexend;}
				}
				h5{
					font: normal normal bold 18px/32px Lexend;
					line-height: 32px;
					margin-bottom:16px;
					color:#1E225C;
					strong, b, a, span{ font: normal normal bold 18px/32px Lexend;}
				}
				p{
					overflow: hidden;
					font-size: 16px;
					line-height: 28px;
					font: normal normal normal 18px/28px Gelasio;
					@include inspiration-body-text;
					color:#171a46;
					text-align: left;
					letter-spacing: 0;
					opacity: 1;
					b,s,a,strong,del,span,li{
						color:#171a46;
					}
				} 
				ol li, ul li, p a, s, b, strong, del, span{
					line-height: 26px;
					font: normal normal normal 18px/28px Gelasio;
					@include inspiration-body-text;
				}
				ul, ol {
					margin-top: 15px;
					margin-bottom: 15px;

				}
				li { 
					margin-top: 10px;
				
				}
				strong, b{
					font-weight:bold;
					a, span {font-weight: bold;}
				}
				.cf-button__text{
					font: normal normal normal 18px/28px Lexend;
					color:white;
				}
				@include breakpoint(medium){
					h2{
						margin-top:45px;
						font-size: 25px;
						font-weight: 700;
						line-height: 1.3;
						//margin-bottom: 26px !important;
						color:#1E225C;
						span{
							font-weight: bold !important;
						}
					}
					h3{
						margin-top:45px;
						font-size: 22px;
						font-weight: 700;
						line-height: 1.3;
						margin-bottom: 16px !important;
						color:#1E225C;
					}
					a {
						font: normal normal normal 18px/30px Gelasio;
						@include inspiration-body-text;
					}
					span {
						font-size:18px;
						line-height: 30px;
					}
					p {
						font: normal normal normal 18px/30px Gelasio;
						@include inspiration-body-text;
						line-height: 30px;
						color:#171a46;
						margin-bottom:34.24px;
						b, strong{
							font: normal normal bold 18px/30px Gelasio;
							@include inspiration-body-text;
						}
					}
					ul, ol {
						margin-top: 34.24px;
						margin-left: 20px;
						margin-bottom: 34.24px;
						li {
							font-size: 18px;
							line-height: 30px;
							color: #171a46;
							margin-left: 30px;
							margin-top: 16px;
							//margin-bottom: 1rem; /* Add spacing between bullet points */
							font: normal normal normal 18px/30px Gelasio;
							@include inspiration-body-text;

							&::marker {
								font-weight: bold; /* Make bullet point numbers bold */
							}

							& > p {
								margin-bottom: 0.5rem;
							}
						}
					}
				}
			}
		}
	}
	&__related-training{
		background-color: #F0F7FF;
		&__container{
			max-width: 700px;
			margin:0px auto;
			padding: 50px 24px;
			@include breakpoint(large){
				padding: 100px 24px;
			}
			h3{
				font-size:16px;
				margin-bottom:50px;
			}
			.training-row-block{
				margin:0px;
				padding: 0px;
				&:hover{
					box-shadow: none;
				}
			}
			.post-row-block-divider{
				margin:30px 0px;
			}
		}
	}
	&__divider{
		height:1px;
		width:100%;
		background-color: #DBE1E8;
	}
	&__related-inspiration{
		&__container{
			max-width: 920px;
			padding: 50px 24px;
			margin:0px auto;
			@include breakpoint(large){
				padding: 100px 24px;
			}

			h3{
				font-size:16px;
				margin-bottom:50px;
			}
			.post-row{
				margin:0px;
				padding: 0px;
				&:hover{
					box-shadow: none;
				}
			}
			.post-row-block-divider{
				margin:30px 0px;
			}
		}
		background-color: #F0F7FF;
	}
}
.banner-post {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 5rem auto 0 auto;
	max-width:920px;
	padding:0px 20px;

	.banner-post-title {
		font-size: 26px;
		font-weight: 600;
		line-height: 34px;
		color: #1e225b;
		max-width: 800px;
		margin-bottom: 15px;
		letter-spacing: 0px;
		@include breakpoint(medium){
			font-size: 30px;

		}
	}

	.banner-post-category {
		font-size: 13px;
		font-weight: 600;
		color: #1e225b;
		margin-bottom: 20px;
		@include breakpoint(medium){
			font-size:14px;
		}
	}

	.banner-post-intro {
		max-width: 800px;
		text-align: left;
		font: normal normal normal 18px/25px Gelasio; // cant have medium with Gelasio
		@include inspiration-body-text;
		letter-spacing: 0;
		color: #888AAA;
		opacity: 1;
		@include breakpoint(medium){
			font-size:20px;
			line-height: 35px;
		}
	}

	.banner-post-image {
		width: 100%;
		height: 100%;
		margin-top: 50px;
		border-radius: 0px 22px 22px 22px;
	}

	.banner-post-author {
		display: flex;
        align-items: center;
        margin-bottom: 25px;
		margin-top: 25px;
		gap:14px;
		@include breakpoint(medium){
			margin-bottom: 30px;
			margin-top: 30px;
		}

		.author-icon {
			width: 45px;
			height: 45px;
			border-radius:100%;
			object-fit: contain;
			border:1px solid #DBE1E8;
		}
		.author-meta-row {
			display: flex;
			align-items: center;
			gap:14px;
		}
		.author-space-divider{
			color:#888AAA;
		}
        .author-text {
            font-size: 14px;
            font-weight: 600;
			color: #1e225b;
        }
		.author-follow{
            font-size: 14px;
			color:#FF9900;
            font-weight: 600;
			text-decoration: none;
		}
		.author-date, .author-readtime {
			font-size: 13px;
            font-weight: 400;
            color: #888AAA;
		}
	}

	.banner-divider {
		height: 1px;
		background-color: #e7ebf1;
		width: 100%;
	}
	.banner-meta {
		display: flex;
		justify-content: space-between;
		border-top: 1px solid #e7ebf1;
		border-bottom: 1px solid #e7ebf1;
		width: 100%;
		padding-top: 10px;
		padding-bottom: 10px;
		margin-bottom: 1rem;

		.banner-meta-icon {
			width: 18px;
			height: 25px;
			// filter to light gray

			&__filter{
				width: 21px;
				height: 21px;
				filter: invert(56%) sepia(5%) saturate(1672%) hue-rotate(199deg) brightness(97%) contrast(88%);
			}
		}

		.banner-meta-container {
			display: flex;
			align-items: center;
			gap:35px;
		}

		.banner-meta-item {
			color: #888AAA;
			display: flex;
			gap:8px;
			align-items: center;
			&:first-of-type{
				margin-left:20px;
			}
			&:last-of-type{
				margin-right:20px;
			}
		}
		.banner-meta-text{
			font-size:14px;
			@include header-text;
			color:#888AAA;
		}

		.favorited {
			filter: brightness(0.5);
		}

		.favorite-link-post {

		}
	}

	.banner-post-quote {
		position: relative;
		display: flex;
		align-items: center;
		margin-top:35px;

		.quote-img-container {
			display: flex; /* Allows the image to shrink */
  			align-items: stretch; /* Stretches the image within */
		}
		.quote-img{
			width: auto;
			max-width: 100%;
			height: 100px; /* Ensures the image can shrink */
			object-fit: cover; /* Adjust this based on your needs */
		}

		.quote-container {
			position: relative;
			max-width: 100%;
			margin: 0 auto;
			text-align: left;
			flex: 1;
			display: flex;
			padding-top:20px;
		}

		.quote-text {
			font-size: 1.4rem;
			font-style: italic;
			color: #1e225b;
			line-height: 1.4;
			font-weight: 550;
			margin: 0;
			padding: 0 5px;
		}

		.quote-mark {
			font-size:24px;
		}

		
	}
}

.hoof_section{
	h3{
		font-size: 20px;
		margin-bottom: 10px;
		color:#1E225C;
	}
	.box_layout {
		display: flex;
		justify-content: start;
		overflow: scroll;
		gap:10px;
		max-width: 752px;
		margin-bottom: 0px;
		padding: 5px 0px;
		.box1 {
			width: 119px;
			min-height: 104px;
			padding: 5px;
			border: 1px solid #1E225C;
			border-radius: 12px;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			margin-right:5px;
			margin-top:5px;
			box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
			p{
				font-size: 14px;
				line-height: 19px;
				&.custom_time{
					margin-bottom: 0;
				}
				margin-bottom:30px;
			}
			transition: box-shadow 400ms;
			&:hover{
				box-shadow: 0px 3px 6px rgba(0,0,0,0.32);
				cursor: pointer;
			}
		}
	}
}
