.home-leader-new.blog_leader .page-header__title{
    margin-bottom: 40px;
    color:#181B4B;
}

.blog__training_artikel {
    margin-top: -70px;
    position: relative;
    max-width: 323px;
    .cta-block__callout__image {
        padding: 0;
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        position: unset;
        img:first-child{
            width: 64px;
            margin-right: -1px;
            position: absolute;
            top: 0px;
            right: 0;
        }
        img:last-child{
            height:350px;
            object-fit: cover;
        }
    }
    .cta-block__intake__image__textbox {
        position: unset;
        margin-top:-41px;
    }
    .cluster-header__background__overlay__reviews{

        margin-left:0px;
        width: 100%;
        max-width: 235px;
        .cluster-header__background__overlay__reviews__text__icon{
            display: none;
        }
        .cluster-header__background__overlay__reviews__text{
            padding: 9px 10px;
            width: 95%;
        }
    }
    .cluster-header__background__designation {
        position:initial!important;
        top:unset!important;
        bottom:unset!important;
        color: #1E225C;
        margin-bottom:10px;
        background-color: #fff;
        left: 0;
        font-weight: 500;
            strong {
                font-size: 15px;
            }
            p{
                padding: 6px 15px;
            }
        }
    span.post-summary-small__date {
        padding: 17px 0 0 14px;
        padding: 17px 0 0 14px;
        font-size: 14px;
        line-height: 18px;
        color: #1E225C;
    }
    .trainer_mobile img{
        max-height:145px;
        object-fit: contain;
    }
}
.page__content{
    margin-top:20px;
    @include breakpoint(large){
        margin-top:20px;
    }
    .introduction{
        display: none;
        color:$color-business-blue;
        @include breakpoint(medium){
            display: block;
        }
    }
}
.add-blogpage-content{
    margin-top:20px;
    margin-bottom:20px;
    @include breakpoint(large){
        margin-top:40px;
        margin-bottom:40px;
    }
}
.add-blogpage-content .cf-button--blue-flat-alt {
    padding: 10px 20px;
    border-radius: 50px; 
    min-width: 225px;
    button{
        width:100%;
        img {
            max-width: 28px;
            margin-right: 12px;
        }
    &:hover{
        color:#fff
    }
    }
}

.videoplayer-initialized{
    background-image: none!important;
    background-color:black;
    iframe{
        height:519px;
    }

}
.introbackground-blog, .hoofdstukken-mobile {
    .twocol__banner{
        background:#fff !important
    }
    .hoof_section{
        padding-left: 40px;
            margin-top: -55px;
        h3{
            font-size: 20px;
            margin-bottom: 10px;
            color:#1E225C;
        }
        .box_layout {
            display: flex;
            justify-content: start;
            overflow: scroll;
            gap:10px;
            max-width: 752px;
            margin-bottom: 0px;
            padding: 5px 0px;
            .box1 {
                width: 119px;
                min-height: 104px;
                padding: 5px;
                border: 1px solid #1E225C;
                border-radius: 12px;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                margin-right:5px;
                margin-top:5px;
                box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
                p{
                    font-size: 14px;
                    line-height: 19px;
                    &.custom_time{
                        margin-bottom: 0;
                    }
                    margin-bottom:30px;
                }
                transition: box-shadow 400ms;
                &:hover{
                    box-shadow: 0px 3px 6px rgba(0,0,0,0.32);
                    cursor: pointer;
                }
            }
        }
    }
    .add-blogpage-content{
        padding-left: 40px;
    }
    .add-webpage-content{
        padding-left: 40px;
        margin: 0;
        .web-page-header{
            min-height: auto;
            margin-bottom: 0;
            .cf-button--blue-flat-alt.button-web-bewaard {
                min-width: 153px;
                border-color: #FF4F57;
                color: #FF4F57;
                margin-right: 8px;
                .cf-button__icon{
                    margin:0;
                    button{
                        color: #FF4F57;
                        font-size: 15px;
                        &:hover{
                            color:#fff;
                        }
                        img{
                            filter:none;
                        }
                    }
                }
                &:hover{
                    border-color:#1E225C;
                }
            }
            .button-web-bekijk{
                max-width: 225px;
                span.cf-button__icon{
                    margin: 0;
                    img{
                        filter:none
                    }
                }
            }
        }
    }
    .page__content {
        padding-left: 10px;
        h2{
            font-size: 25px;
            line-height: 50px;
            max-width: 734px;
            margin-bottom: 26px !important;
            color:#181B4B;
            span{
                font-weight: bold !important;
            }
        }
        h3{
            font-size: 25px;
            line-height: 50px;
            max-width: 734px;
            margin-bottom: 26px !important;
            color:#181B4B;
        }
        p {
            font-size: 18px;
            line-height: 30px;
            color:#181B4B;
            max-width: 734px;
        }
        ul,ol{
            //margin-left: 0;
            //list-style-type: none;
            margin-left: 20px;
            max-width: 734px;
            li{
                font-size: 18px;
                line-height: 30px;
                color:#181B4B;
                & > p { margin-bottom: 0.5rem;}
            }
        }
    }
    .row.add-blogpage-content{
        .page-header {
            min-height: auto;
            background: none;
            border-bottom: none;
            padding-left: 50px;
            margin:40px 0
        }
    }
    #training-links{
       border:0;
       padding-left: 40px;
    }
}
.trainingen-heder-content{
    margin-top:40px;
    @include breakpoint(large){
        margin-top:80px;
    }
    width:100%;
}
    .trainingen-blog {
        width:100%;
        max-width: 868px;
        margin:0 auto;
        display: flex;
        justify-content: space-between;
        grid-row-gap: 10px;
        @include breakpoint(large){
            grid-row-gap: 40px;
        }
        flex-wrap: wrap;
        align-items: center;
        &:last-child{
            margin-bottom: 120px;
        }
        h3{
            font-size: 39px;
            line-height: 50px;
            margin-bottom: 40px;
            text-align: center;
            width: 100%;
            color:#1E225C;
            margin-bottom: 54px;
            @include breakpoint(large){
                margin-bottom: 24px;

            }
        }
        .training-grid-block{
            display: inline-block;
            width: 47.2%;
            margin: 0px 0px;
            .training-grid-block__image__overlay{
                border-radius: 4px 4px 0px 0px;
            }
            .blue-flat-alt-button{
                position: absolute;
                right: 11px;
                bottom: -18px;
                button{
                    width: 50px;
                    height: 50px;
                    border: 1px solid #1E225C;
                    border-radius: 100px;
                    background-color: #fff;
                    box-shadow: 0px 10px 30px 0px rgba(157, 171, 192, 0.2);
                    img{
                        width:28px;
                    }
                }
            }
            p.training-grid-block__title{
                color:#1E225C;
                margin-bottom: 5px;
                text-align: left;
            }
            p.training-grid-block__intro {
                font-size: 16px;
                line-height: 25px;
                text-align: left;
            }
        }
    }
.web-design-artikel{
    .cluster-header__content-post__text{
        padding:0px!important;
    }
}
.web-design-artikel .cluster-header__content-post {
        width: 100%;
        padding: 0rem 2rem 60px;
        @include breakpoint(large){
            padding-right:0px;
        }
        display: flex;
        align-items: center;
        .cluster-header__content-post__text {
            max-width: 595px;
            margin-left: auto !important;
            p{
                margin-bottom:20px;
            }
        }
    h2{
        font-size: 36px;
        line-height: 45px;
        margin-bottom: 22px !important;
        color:#1E225C;
    }
    p{
        font-size: 18px;
        line-height: 27px;
    }
    .columns{
        padding:0;
        margin:0;
        .contact__main__form {
            max-width: 100%;
            margin-left: -3px;
            .form-col-main{
                max-width: 496px;
                .row{
                    padding:0;
                    margin:0
                }
                .form-col{
                    margin-bottom:20px;
                    label{
                        @include header-text;
                        font-size: 14px;
                        font-weight: bold;
                        line-height: 18px;
                        color:#181B4B;
                    }
                    input{
                        border-color: #181B4B;
                        border-radius: 4px;
                        height: 51px;
                        padding-left: 22px;
                        box-shadow: none;
                        color: #181B4B;
                        font-size: 16px;
                        ::placeholder{
                            color: #181B4B;
                            font-size: 16px;
                        }
                    }

                }
                .cluster-header__content-post__text__buttons__button{
                    button{
                        padding: 16px 15px;
                        width: inherit;
                        font-size: 18px;
                        min-width: 161px;
                        margin-top: 9px;
                    }
                }
                br{
                    display:none
                }
            }
        }
        &.cluster-header__background.align-self-top{
            height:auto;
            .cluster-header__background__text{
                display: block;
                position: relative;
                span{
                    width: 100%;
                    font-size: 700px;
                    padding: 40px 0;
                }
            }
            .cluster-header__background__overlay{
                    bottom: -10px;
                    left: 0;
                    right: 0;
                    display: flex;
                    justify-content: center;
                    margin: 0 auto;
                    flex-direction: column;
                    max-width: 440px;
                    .cluster-header__background__image{
                        width: 100%;
                        overflow: visible;
                        margin-left:0;
                        height:fit-content;
                        img{
                            position: absolute;
                            transform: translateX(-50%);
                            left: 55%;
                            min-height: 350px;
                            max-height:350px;
                            bottom: 0px;
                            @include breakpoint(large){
                                min-height: 50vh;
                                max-height:550px;
                                left: 70%;

                            }
                        }
                    }
                    .cluster-header__background__designation{
                        position: static;
                        margin-bottom: 8px;
                        margin-left: 7px;
                        p{
                            font-size:14px;
                            line-height: 20px;
                        }
                    }
                    .cluster-header__background__overlay__reviews{
                        
                        @include breakpoint(large){
                            width: 443px;
                            margin-left:0px;
                            img{
                                width:100px;
                            }
                        }
                        
                    }
                    .cluster-header__background__overlay__reviews__text {
                        padding: 8px 8px 8px 0;
                        width: 100%;
                    }
                }
            }
        }
    
    }
    .training__banner_webinar{
        height: 100%;
        max-width: 1550px;
        margin: 0 auto;
        background-size: auto;
        position: relative;
        padding-bottom:42px;
        .inspiration-block__item__image{
            z-index: 1;
            padding-bottom: 100px;
            .videotext-block__video__container{
                position: unset;
                #vid-icon{
                    margin-left: 0;
                    margin-top: 0;
                }
            }
        }
            .inspiration-block__item__image__background{
                border-radius: 4px;
                min-height: 678px;
                background-position: top;
                max-width: 1071px;
                margin: 0 auto;
                
            }
        }
        .training__banner_case{
            height:519px;
            .inspiration-block__item__image{
                padding:0;
                border:1px solid #e7ebf1;
                .inspiration-block__item__image__background {
                    border-radius: 0;
                    min-height:219px;
                    @include breakpoint(large){
                        min-height:519px;
                    }
                    background-position: center !important;

					&.top {  
                        background-position: top !important;}
					&.center { 
                        background-position: center !important;}
					&s.bottom { 
                        background-position: bottom !important;}

                    
                    iframe {
                        width:100%;
                        height:219px;
                        @include breakpoint(large){
                            height:526px;
                        }
                    }
                }
                .videotext-block__video__container{
                    position: unset;
                    #vid-icon{
                        margin-left: 0;
                        margin-top: 0;
                        width: 120px;
                        height: 120px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding:0;
                        img{
                            height: 70px;
                            width: 70px;
                        }
                    }
                }
            }
        }
        .overlay_img{
            position: absolute;
            bottom: 108px;
            height: 602px;
            width: 1348px;
            margin: 0 auto;
            left: 0;
            right: 0;
        }
        .case-page-header {
            margin-top: 40px;
            min-height: auto;
           .cf-button--blue-flat-alt{
                .cf-button__icon{
                    margin:0;
                    img{
                        height: auto;
                    }
                }
            &.button-alt-blog{
                min-width: 225px;
                margin-right: 8px;
                img{
                    filter:unset;
                    height: auto;
                }
            }
           
        }
    }
          
@media handheld, only screen and (max-width: 1579px) {
    .row.cluster-header-blog{
        margin-right: 0;
        margin-left: 0;
        .cluster-header__content-post{
            margin: 0;
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .cluster-header__content-post{
        .cluster-header__background__text span{
            font-size: 600px!important;
        }
    }
}

@media handheld, only screen and (max-width: 1250px) {
    .cluster-header__content-post{
        .cluster-header__content-post__text{
            padding-left: 20px !important;
        }
        .cluster-header__background__text span{
            font-size: 500px!important;
        }
    } 
    .introbackground-blog, .hoofdstukken-mobile{
        .hoof_section {
            margin-top:0;
            .box_layout{
                .box1{
                    width: 109px;
                    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
                }
            }
        }
    }
}
@media handheld, only screen and (max-width: 1140px) {
    .introbackground-blog, .hoofdstukken-mobile{
        .hoof_section {
            .box_layout{
                
                display: flex;
                flex-wrap:nowrap;
                gap:10px;
                overflow: scroll;
                .box1{
                    min-width: 119px;
                    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
                }
            }
        }
    }
    .cluster-header__content-post{
        .cluster-header__background__text span{
            font-size: 430px!important;
        }
    }
}

@media handheld, only screen and (max-width: 1023px) {
    .sidebar-blog{
        border-bottom:1px solid;
    }
    .twocol__sidebar-training__background{
        padding:0px 2rem;
    }
    .blog__training_artikel{
        margin-top:0;
        margin-bottom: 30px;
    }
    .introbackground-blog{

        .row.add-blogpage-content{
            .page-header {
                padding-left: 20px;
            }
        }
        .page__content{
            padding-left: 0px;
            p{
                padding-left: 0px;
                max-width:100%
            }
        }
    }
    .cluster-header__content-post{
        flex-direction: column;
        justify-content: center;
        .cluster-header__background__text{
            margin-left:-2rem;
            margin-right:-2rem;
            span{
                text-align:center
            }
        }
        .cluster-header__content-post__text {
            padding-left: 0px !important;
            margin: 0 auto !important;
        }
        .cluster-header__background{
            width:100%
        }
    }
    .introbackground-blog, .hoofdstukken-mobile {
        .hoof_section{
            padding-left: 0;
            .box_layout{
                max-width: 100%;
                display: flex;
                flex-wrap:nowrap;
                gap:10px;
                overflow: scroll;
                -ms-overflow-style: none;  /* Internet Explorer 10+ */
                scrollbar-width: none;  /* Firefox */

                &::-webkit-scrollbar {
                    width: 0;  /* Remove scrollbar space */
                    background: transparent;  /* Optional: just make scrollbar invisible */
                }

                .box1 {
                    width: 119px;
                    min-height: 104px;
                    box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
                }
            }
        }
        .add-blogpage-content{
            padding-left: 0px;
        }
    }
    .introbackground-blog {
        .add-webpage-content {
            padding-left: 0px;
        }
        #training-links{
            padding-left: 0px;
        }
    }
   
}

@media handheld, only screen and (max-width: 989px) {
    .trainingen-blog{
        padding: 0px 2rem;
    }
}

@media handheld, only screen and (max-width: 767px) {
    .trainingen-blog{
        flex-direction: column; 
        .training-grid-block{
            width: 100%;
            margin: 0px 0px 20px;
            padding: 0;
            .training-grid-block__image{
                height:auto;
                & > img{
                    height:auto
                }
            }
            .training-grid-block__content__text{
                height: auto;
            }
        }
        h3{
            font-size: 20px;
            line-height: 30px;
            text-align: start;
            margin-bottom: 26px;
        }
        &:last-child {
            margin-bottom: 50px;
        }
    }
    .introbackground-blog{
        padding:0 2rem;
        .page__content{
            h2{
                font-size: 25px;
                line-height: 30px;
                color:#1E225C;
            }
            h3{
                font-size: 25px;
                line-height: 30px;
                color:#1E225C;
            }
            p{
                overflow: hidden;
                font-size: 16px;
                line-height: 26px;
                color:#1E225C;
            } 
            ul li, p a{
                font-size: 16px;
                line-height: 26px;
                color:#1E225C;
            }
        }
    } 
    .cluster-header__content-post{
        padding:50px 0;
        .cluster-header__content-post__text {
            //padding: 0 20px !important;
            max-width: 100%;
        }
        .columns.cluster-header__background.align-self-top{
            .cluster-header__background__overlay{
                bottom:0px;
                width: auto;
               // padding: 0 20px;
                .cluster-header__background__overlay__reviews{
                    width: auto;
                    left: 0px;
                }
                .cluster-header__background__designation{
                    left: 0px;
                    bottom: 105px;
                }
                .cluster-header__background__image{
                    display: flex;
                    justify-content: center;
                    left:0;
                    right:0;
                    img{
                        transform: translateX(0%);
                        left: 0%;
                    }
                }
            }
            .cluster-header__background__text{
                 span {
                font-size: 300px !important;
                padding-right: 0;
                 }
        }
    }
}


.introbackground-blog, .hoofdstukken-mobile {
    .hoof_section {
        .box_layout {
            max-width: 100%;
            margin: 0;
            //justify-content: space-between;
            gap:10px;
            overflow: scroll;
            .box1{
                width: 119px;
                margin: 0 0px 10px;
                min-height: 104px;
                box-shadow: 0px 3px 6px rgba(0,0,0,0.16);
            }
        }
    }
    .add-webpage-content{
        display: flex;
        justify-content: center;
        .cf-button--blue-flat-alt{
            margin-top: 10px;
            margin-right:0
        }
    }
    } 
    .cluster-header__content-post{
        .columns{
             .contact__main__form {
                margin:0
             }
        }
    } 
    .training__banner_webinar {
        .inspiration-block__item__image{
        padding-bottom: 0;
        }
    }

}
@media handheld, only screen and (max-width: 639px) {}

@media handheld, only screen and (max-width: 550px) {}

@media handheld, only screen and (max-width: 479px) {
    .blog__training_artikel{
        max-width: 100%;
    }
}
.trainer_mobile{
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap:30px;
    img{
    max-height:145px;
    object-fit: contain;
    }
    .text-next-image-trainer-mobile{
        //margin-top: 30px;
        //margin-left: 0px;
        width: 100%;
        @include header-text;
        color:$color-business-blue;
        span {
            @include header-text;

        }
    }
}
.cluster-header__content-post{
    h1{
        margin-top:20px;
        margin-bottom:20px;
    }
}
@media handheld, only screen and (max-width: 639px) {
    .sidebar-blog{
        border-bottom: 1px solid;
    }

    .training__banner__image_blog{
        display:block !important;
        height:250px;
        .videotext-block__video__container {
            height:100%;
        }
    }
    .inspiration-block__item__image{
        padding:0;
    }
    .inspiration-block__item__image__background-blog{
        min-height:180px !important;
    }
    .banner-case-blog{
        display:block !important;
        height:250px;

    }
    .banner-case-blog-background{
        min-height:250px !important;
    }
    .cluster-header__content-post{
        h1{
            margin-top:20px !important;
            margin-bottom:20px;
        }
        .cluster-header__background__text{
            margin-top:150px;
        }
    }

}
@media handheld, only screen and (max-width: 1000px) {
    .cluster-header__content-post{
        margin-bottom: 25px;
        padding: 0 2rem;
    }
    .rowbuttommobile-login{
        max-width:100% !important;
    }
    .background-overlay-login{
        padding-left: 20px !important;
    }
    .background-image-overlay-login{
        bottom:40px;
    }
    .login-clusterheader-backgroundtext{
        margin-top: 50px !important;

        span{
            margin-bottom: 100px;
        }
    }

}
.header-mobile-title{
    position: relative;
    padding-top:20px;
    padding-bottom:25px;
    h1{
        margin:0px!important;
    }

}
.header-mobile-blog{
    margin-top:24px;
    margin-bottom: 0px;
    padding: 0px 2rem;
    
    .page-title-blog-mobile{
        font-size:25px;
        font-weight: 800;
        color: #1e225d;
        font-family: 'Lexend', sans-serif;
    }
    .page-omschrijving-blog-mobile{
        font-size:16px;
        margin-top:15px;
        @include breakpoint(medium){
            display: none;
        }
    }
}
.blog_head_labels{
    display: flex;
    gap:25px;
    align-items: center;
}
.blog_head_title{
    position: relative;
    padding-top:25px;
    padding-bottom:55px;
    h1.page__title{
        color:$color-business-blue!important;
        margin:0px!important;
        margin-bottom: 0px!important;

    }
}
.labelsoortpost{
    background-color: #FFF500;
    border-radius: 100px;
    color: #1e225d;
    font-size:14px;
    font-weight:600;
    padding: 5px 13px 4px 13px;
    text-transform: capitalize;
    font-family: 'Lexend', sans-serif;
    display: inline-block;
}
.labelsoortcatogorie{
    margin-left:5px;
    color: #1e225d;
    font-size:14px;
    font-weight:600;
    font-family: 'Lexend', sans-serif;
    display: inline-block;
}
.button-bar{
    display: flex;
    align-items: center;
    gap:15px;
}
.button-bewaar-blog{
    min-width: 0;
    border-radius: 50px;
    padding: 13px 21px;
    border:1px solid $color-business-blue;
    width: fit-content;
    text-decoration: none;
    display:inline-flex;
    align-items: center;
    gap:6px;
    img { width:24px; filter: invert(9%) sepia(64%) saturate(3500%) hue-rotate(231deg) brightness(86%) contrast(90%); }
    transition: all 400ms;





    .like-image-blog-mobile{
        width: 16px;
    }
    span{
        font-family: 'Lexend', sans-serif;
        color: #1e225d;
        font-size:14px;

    }
    &:hover{
        border:1px solid $color-business-blue;
        background:$color-business-blue;
        img {
            filter: invert(98%) sepia(1%) saturate(50%) hue-rotate(347deg) brightness(116%) contrast(100%);
        }
        span{
            color:white;
        }
    }

    &.bewaarknop-trainingen{
        border:none;
        border-radius: 0;
        height:100%;

        span{
            font-size:15px;
        }
        &:hover{
            border-bottom: 1px solid #1E225C;
            background:none;
            span{
                font-weight: 600;
                color:$color-business-blue;
            }
            img {
                filter: invert(9%) sepia(64%) saturate(3500%) hue-rotate(231deg) brightness(86%) contrast(90%);
            }
        }
    }
}
.button-bewaar-blog-mobile{
    min-width: 0;
    border-radius: 10px;
    padding: 7px 15px;
    border:1px solid #DDDDDD;
    width: fit-content;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap:6px;
    transition: all 400ms;
    .like-image-blog-mobile{
        width: 16px;
    }
    span{
        font-family: 'Lexend', sans-serif;
        color: #1e225d;
        font-size:14px;

    }
    &:hover{
        border:1px solid $color-business-blue;
        background:$color-business-blue;
        img {
            filter: invert(98%) sepia(1%) saturate(50%) hue-rotate(347deg) brightness(116%) contrast(100%);
        }
        span{
            color:white;
        }
    }
}
.box-likes-bewaar-blog-mobile{
    margin-top:20px;
    display: flex;
    gap:10px;
    margin-bottom: 20px;
    .button-bewaar-blog-mobile.likes{
        span{
            font-weight: 600;
        }
        &:hover{
            border:1px solid #DDDDDD;
            background-color: transparent;
            span{
                color:$color-business-blue;
            }
        }
    }
}
.container-text-post-clusterheader{
    margin-right:0 !important;
}
#video2{
    top:100px !important;
}
.rowbuttommobile-login{
    max-width:80%;
}
.whitefade-notloggin-footer{
    display:block;
    width: 100%;
    height: 200px;
    background-image: linear-gradient(rgba(255,255,255,0), rgb(255, 255, 255) 40%);
    margin-top: -150px;
    pointer-events: none;
    position: relative;
}
.inspiratie-nieuwsbrief{
    input{
        width:100%;
    }

    textarea{
        border: 1px solid #1e225d;
        border-radius: 8px;
        background-color: transparent;
        padding: 14px 20px;
        height: 100px;
        max-width: 95%;
    }

    &.Option{
        .Option_nieuwsbrief{
            border: 1px solid #1e225d;
            border-radius: 8px;
            background-color: transparent;
            padding: 14px 20px;
            width:95%;
            input{
                margin-bottom: 0;
                width: fit-content;
            }
            label{
                position: initial;
                margin-left:0;
                width: fit-content;
            }

            &.tekstvak{
                width: 95%;
                display: block;
            }
            &__box{
                display: grid;
                grid-template-columns: 1fr 1fr;
                width: fit-content;
            }
        }
    }

}
.button-alt-blog:hover{
}
.inspiratie-arrow-back{
    height: 50px;
    width: 50px;
    position: absolute;
    bottom: -25px;
    border: 1px solid black;
    background: white;
    border-radius: 50px;
    display: grid;
    align-content: center;
    justify-content: center;
    z-index: 2;
    svg{
        width:24px;
        height:24px;
    }
}

.button-bar .selected{
    border:1px solid red;

    span{
        color:red;
    }
    img{
        filter: invert(44%) sepia(85%) saturate(3223%) hue-rotate(329deg) brightness(106%) contrast(100%);
    }
}