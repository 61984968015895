.mobile-container{
  	padding-left:2rem!important;
  	padding-right:2rem!important;
	padding-bottom: 20px;
  	&.small{
		padding-right: 0.625rem!important;
		padding-left: 0.625rem!important;
  	}
  	@include breakpoint(large){
		padding-left:0rem !important;
		padding-right:0rem !important;
  	}
	.containermenu{
		//max-width: 100rem;
	}
  }
.home{
	.home-leader-new{
		&::before{
			content: "";
			background-image: url(/images/dot-bgv5.svg);
			height: 15%;
			width: 15%;
			position: absolute;
			background-repeat: repeat;
			z-index: 2;
			background-position: center;
			background-size: 45px;
			top:auto;
			@include breakpoint(large){
				height: 70%;
				width: 10%;
				top:-50px;
			}
		}
	}
}
.home-leader-new {
  //background: white;
  height: 100%;
  position:relative; 
  //overflow-x:hidden;
  /*@include breakpoint(xlarge) {
  	background-position: center 40%;
  }*/
  

  &__overlay{
	display:none;
	@include breakpoint(large){
		display:block;
  	  width:100%;
  	  height:100%;
  	  //margin-left:50%;
      //background:white;
      //border-radius: 30px 0px 0px;
      position: absolute;
      //bottom:0px;
      //left:15%;
      z-index: -1;
      overflow:hidden;
      //background:rgba(32, 27, 102, 0.5);
      //background:rgba(0, 0, 0, 0.4);
      color:#00C4C2;
      font-size: 900px;
      font-weight:800;
      letter-spacing: -90px;
      line-height: 705px;
      text-align:right;
  	  @include header-text;
	  span{
		@include header-text;
		display: inline-block;
		overflow: hidden;
		width: 60%;
	  }		
	}

  }
  &__column{

	//padding-left:2rem!important;
	//padding-right:2rem!important;
  }
  &__container {
    height: 100%;
    padding-top: 50px;
    position:relative;
    z-index:2;
	display: grid;
	justify-content: center;
	justify-items: center;
	padding-bottom: 60px;

	&::before{
		content: "";
		background-image: url(/images/dot-bgv5.svg);
		height: 26%;
		width: 25%;
		position: absolute;
		background-repeat: repeat;
		top: auto;
		bottom: -100px;
		right: -30px;
		z-index: 2;
		background-position: center;
		background-size: 45px;
		@include breakpoint(medium){
			bottom: -150px;
			right: -50px;
			height: 60%;
			width: 20%;
		}
	}

    @include breakpoint(large){
    	padding-top: 75px;
    }
	& > .row{
		height:100%;
		margin:0px;
	}
	&__left{
		padding-right: 0rem;
    	padding-left: 0rem;
    	margin-bottom:20px;
    	@include breakpoint(large){
			padding-right: 0.625rem;
    		margin-bottom:0px;
    	}
	
	}
	&__right{
		background-color:white;
    	@include breakpoint(large){
    		background-color:transparent;
			//margin-bottom: 70px;
    	}
	
	}
  }
  
  &__subtext {
  		@include raleway;
  		font-weight:400;
  		font-size:rem-calc(18px);
    	
		@include breakpoint(medium) {
		  text-align:left;
    	  padding-bottom:20px;
    	  max-width:80%;
		}
		
		&.rating{
			border-radius: 10px;
    		background: #f2f8ff;
    		display:flex;
    		margin-bottom:20px;
    		.start__reviews__ratings__text{
    			display:inline-block;
    		}
    		@include breakpoint (medium){
    			margin-bottom:initial;
    			background:transparent;
    			display:block;
    		}
		}
  }
  .inline-spacer { display:block; }

  &__buttons {
	display: grid;
	justify-content: center;
	column-gap: 10px;
	margin-top: 42px;
	flex-direction: column;

	.cf-button--orange-flat, .cf-button--blue-alt {
		display: inline-flex!important;
		width: fit-content !important;
		justify-content: space-between;
		margin-bottom:0;
		width: 100% !important;
		margin-top:10px;
		@include breakpoint(large up){
			width: fit-content !important;
			font-size:17px;
		}
		padding: 13px 35px;
		.cf-button__text{
			font-weight:600;
		}
	}
	.cf-button.shadow {
		margin-right:0px;
		margin-bottom:10px;
	}  	

	.vakgebieden-button{
		@include breakpoint(large){
			width:280px!important;

		}
	}
	@include breakpoint(400px) {
		.cf-button.shadow {
			margin-right:40px;
			margin-bottom:0px;
		}
		.inline-spacer { display:inline; }
  	}  	
	@include breakpoint(medium) {
	  margin-top: 30px;
	  text-align:left;
	  flex-direction: unset;
	  display:flex;

		.cf-button--orange-flat, .cf-button--blue-alt {
			width: initial;
		}
		.cf-button--blue-alt{
			margin-top:auto;
		}
	}

	
  }
  &__date {
  	font-weight:600;
  	color:$color-orange;
  }
  
	
  @include breakpoint(400px) {
    //height: 550px;
  }

  @include breakpoint(large) {
    
  height: 700px;
  position:relative; 
    //height: 575px;
	margin-top:50px;
	&__overlay {
  	  //width:50%;
      //left:50%;
      //height: 250px;
    }
    
    &__container {
      height: 575px;
      padding-top: 25px;
    }
    
  }

  @include breakpoint(large) {
    height: 700px;
	margin-top:30px;
	&__overlay {
  	  //width:50%;
      //left:50%;
      //height: 270px;
    }
    
    &__container {
      height: fit-content;
      padding-top: 25px;
      padding-left: 10px;
    }
    &__subtext {
    	display:block;
    }
    &__date{
    	margin-bottom: 0.5rem;
    }
  }

  @include breakpoint(xlarge) {
    height: 700px;
	margin-top:50px;
	&__overlay {
  	  //width:50%;
      //left:50%;
      //height: 270px;
    }
    
    &__container {
      height: fit-content;
      padding-top: 25px;
      padding-left: 20px;
    }
  }

  @include breakpoint(xxlarge) {
    height: 700px;
	
	&__overlay {
  	  //width:50%;
      //left:50%;
      //height: 270px;
    }
    
    &__container {
      height: fit-content;
      padding-top: 25px;
      padding-left: 20px;

    }
  }
  &--404{
  	height:100px;
  	@include breakpoint(medium) {
  	height:200px;
  	}
  	@include breakpoint(large) {
  	height:200px;
  	}
  	@include breakpoint(xlarge) {
  	height:200px;
  	}
  	@include breakpoint(xxlarge) {
  	height:200px;
  	}
  }
  
  &__title {
    @include raleway;
    font-family:"Lexend",sans-serif;
    font-weight: 900;
    width: 100%;
	height:fit-content;
    font-size: 26px;
    line-height: 1.2;
    letter-spacing:0px;
    padding: 0;
	text-align: center;
    @include breakpoint(medium) {
	  font-size: 45px;
	  margin-bottom: 40px;
    }
    @include breakpoint(large) {
	  font-weight: 800;
      font-size: 45px;
	  letter-spacing: -1.8px;
      max-width: 100%;
    }
    @include breakpoint(xlarge) {
      font-size: 45px;
    }

  }
  
  &__subtitle {
    @include raleway;
    font-weight: 500;
    width: 100%;
    line-height: 1.1;
    padding: 0;
    padding-bottom:15px;
    text-align:center;
    font-size: 1rem;

    @include breakpoint(360px) {
      font-size: 1rem;
    }

    @include breakpoint(420px) {
      font-size: 1rem;
    }

    @include breakpoint(medium) {
      font-size: 1rem;
      font-weight: 800;
      text-align:left;
    	
    }
    @include breakpoint(large) {
      font-size: 1.5rem;
      max-width: 1200px;
      padding-bottom:20px;
    }
    @include breakpoint(xlarge) {
    }

	

  }
  &__text{
	text-align: center;
	margin-top:25px;
	@include breakpoint(large) {
		max-width:800px;
	}

  }

  &__action {
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: rem-calc(24px);
  }

  &__button {
    width: 280px;
    position: absolute;
    top: 250px;
    left: 350px;
  }
  
  &__agenda{
  	border-radius:12px;
  	background-color:white;
    box-shadow: 0px 12px 40px 0px rgba(157, 171, 192, 0.2);
    
  		margin-left:0.9rem;
  		margin-right:0.9rem;
  	position:relative;
  	z-index:10;
    @include breakpoint(large) {
  		margin-left:1.35rem;
  		margin-right:0.9rem;
  		position:initial;
  		margin-bottom: -20px;
	}
	.test{
		display:none;
		@include breakpoint(medium up){
			display:block;
		}

	}
	.test2{
		display:block;
		@include breakpoint(medium up){
			display:none;
		}
	}
  	&__background {
  		position:relative;
  		text-align:center;
  		margin-bottom:-90px;
  		@include breakpoint(medium){
  			margin-bottom: -50px;
  		}
  		&__text{
  			overflow:visble;
			//background:rgba(32, 27, 102, 0.5);
			//background:rgba(0, 0, 0, 0.4);
			color:#00C4C2;
			font-size: 290px;
			font-weight:800;
			letter-spacing: -32px;
			line-height: 255px;
			text-align:center;
			margin-left:-20px;
			@include header-text;
			@include breakpoint(medium){
				font-size: 300px;
			}
			span{
				@include header-text;
				display: inline-block;
				overflow: visible;
				width: 100%;
				@include breakpoint(medium){
				width: 90%;
				}
			}		
  		}
  		&__image{
  			position:absolute;
  			z-index:1;
  			bottom:0px;
  			width:100%;
  			height:100%;
  			background-image: url('https://www.competencefactory.nl/wordpress/wp-content/uploads/2021/11/Esther-Mutsaers-4-1_adobespark-e1636931608789.png');
			  background-repeat:no-repeat;
			  background-size: 180px;
			  background-position: 50% 100%;
  		}
  	}
	&__title{
		margin-top:40px;
		font-weight:700;
		font-size: rem-calc(18px); 
    	text-align:left;
		border-radius: 8px 8px 0px 0px;
		padding:15px 20px;
		color:$color-blue;
		margin-bottom:0px;
		display:block;
		a{
			display:none;
			font-weight:400;
			color:$color-orange;
    		font-size: rem-calc(14px);
    		@include breakpoint(medium) {
    			display:inline;
    		}
		}
		
    	@include breakpoint(medium up) {
    		margin-top:0px;
    		margin-bottom:0px;
			padding:0px;
			padding-bottom:13px;
			padding-left:30px;
			padding-top:22px;
			padding-right:18px;
    	}
	}
	&__link{
		padding: 10px 15px;
		@include breakpoint(medium) {
			//display: grid;
			//grid-template-columns: 10fr 1fr;
			text-align: right;
			border-radius: 12px;
			padding-right: 18px;
			padding-top:17px;
			padding-bottom:17px;
			margin-bottom: 0px;
		}
		a{
			font-weight:500;
			color:$color-orange;
    		font-size: 15px;
			text-decoration: none;
			transition: text-decoration 400ms;
    		@include breakpoint(medium) {
				text-align: end;
				//margin-right: 10px;
    		}
			&:hover{
				text-decoration: underline;
				
			}
		}
		.cf-button__icon{
			color:$color-orange;
			margin-left: 10px;
			@include breakpoint(medium){
				margin-left: .9vw;
			}
		}
		@include breakpoint(medium up){
			padding-left:25px;
			padding-right: 18px;
			padding-top:17px;
			padding-bottom:17px;
			background-color:white;
		}
	}
	&__item{
		display:block;
		background:white;
		margin:0px 0;
		padding:15px 20px;
		transition: background 300ms, color 300ms;
		border-bottom: 1px solid #E5E5E5;
		&:nth-of-type(1n+2){
			display:none;
		}
    	@include breakpoint(medium) {
			padding:5px 20px;
			padding-left:30px;
			padding-right:18px; 
			//margin:5px 0;
			display: grid;
			grid-template-columns: 9fr 1fr;
			align-items: center;
			text-decoration: none;
		}
    	@include breakpoint(large) {
			//&:nth-of-type(1n+3){
			//	display:block;
			//}

    	}
		&__date{
			height:55px;
			background-color:$color-business-blue;
			width: 55px;
			border-radius: 2px;
			display: grid;
			justify-items: center;
			align-items: center;
			padding: 5px;
			p{
				color: white;
				text-align: center;
				font-size: rem-calc(18px);
				margin: 0;
				line-height: 1;
				font-weight: 800;
				font-family:"Lexend",sans-serif;
				text-transform: uppercase;
				span{
					font-size: rem-calc(16px);
				}
			}
		}
		&__info{
			display:inline-block;
			p {
    			font-size: rem-calc(16px);
				color:$color-business-blue;
				margin:0px;
				padding:0px;
			}
			&__title{
				font-weight:500;
				word-break: break-word;
				word-break: break-all;
				hypens:auto;
			}
			&__date{
			
			}
			
    		@include breakpoint(medium) {
				width:90%;
    		
    		}
    		@include breakpoint(large) {
				width:90%;
    		}
		}
		&__action{
			display:inline-block;
			//width:15%;
			float:right;
    		font-size: rem-calc(16px);
			text-align: center;
    		& > div {
    			line-height:50px;
    			border-radius:50%;
    			background:$color-orange;
    			color:white;
    			width:50px;
    			height:50px;
    			vertical-align:center;
    			text-align:center;
    			@include breakpoint(medium) {
    				line-height:60px;
					width:60px;
					height:60px;
				}
    		}
		}
		&:hover{
    		background:$color-business-blue;
    		& > div > p {
    			color:white;
    		}
			& > .home-leader-new__agenda__item__date{
				background-color:white;
			}
			& > .home-leader-new__agenda__item__date > p{
				color:$color-business-blue;
			}
			& > .home-leader-new__agenda__item__action > span{
				color:white;
			}
			.cf-button--orange-flat-alt{
				@include cf-button-orange-alt-hover;
			}
		}
		&--event{
			display:block;
			//background:$color-business-blue;
			background:white;
			margin:0px 0;
			padding:15px 20px;
			transition: background 300ms, color 300ms;
			border-bottom: 1px solid #E5E5E5;
			@include breakpoint(medium up) {
				//margin:5px 0;
				padding:5px 20px;
				padding-left:30px;
				padding-right:18px; 
				display: grid;
				grid-template-columns: 9fr 1fr;
				align-items: center;
				text-decoration: none;
			}
			.home-leader-new__agenda__item__info{
				p {
					color:$color-business-blue;
				}
				&__header{
					color:$color-business-blue;
					font-weight:600;
    				font-size: rem-calc(16px);
				}
			}
			.home-leader-new__agenda__item__action{
				display:inline-block;
				//width:15%;
				float:right;
				font-size: rem-calc(16px);
				& > div {
					line-height:50px;
					border-radius:50%;
					background:$color-orange;
					color:white;
					width:50px;
					height:50px;
					vertical-align:center;
					text-align:center;
					@include breakpoint(medium) {
						line-height:60px;
						width:60px;
						height:60px;
					}
				}
			}
			
			&:hover{
				background:$color-business-blue;
				& > div{
					color:white;
				}
				& > div > p {
					color:white;
				}
				& > .home-leader-new__agenda__item__date{
					background-color:white;
				}
				& > .home-leader-new__agenda__item__date > p{
					color:$color-business-blue;
				}
				.cf-button--orange-flat-alt{
					@include cf-button-orange-alt-hover;
				}
			}
		}
	}
  }
}

.header-usp{
	div{
		justify-content: center;
		flex-direction: column-reverse;
		@include breakpoint(medium){
			flex-direction: unset;
		}

	}
	&__item{
		padding:0px;
		max-width:35%;
		@include breakpoint(large){
			padding:10px 0px;
			max-width:100%;
			flex:0 0 9%;
			max-width:40%;
		}
		img { 
			//filter: invert(16%) sepia(41%) saturate(1875%) hue-rotate(211deg) brightness(84%) contrast(102%);
		}

		&.springtest{
			display: flex;
			flex-direction: column;
			align-items: center;
			font-weight: 600;
			margin-left: -50px;
			@include breakpoint(medium){
				margin-left: initial;
			}
			@include breakpoint(large){
				margin-right:50px;
			}
			span {
				@include header-text;
				color:$color-business-blue;

			}
			svg path{
				fill: #F5C80B;
			}
			img{
				max-width: none;
				width: 123px;
				height:64px;
			}
			@include breakpoint(large){
				width: 120x;
				height:60px;
			}
		}
		&.bestopleider{
			img{
				max-width: none;
				width: 123px;
				height:75px;
			}
		}
	}
	.icon-div{
		display:flex;
		flex-direction: initial;
		align-items: center;
		margin-top:26px;
		column-gap:15px;
	}
	&__items {
		@include breakpoint(medium){
			flex: inherit;
		}
		@include breakpoint(large){
			padding-left:50px;
		}
		& ul{
			margin:0px;
			margin-top:15px;
			padding:0px;
			list-style-type:none;
			@include breakpoint(medium){
				margin:0px;
			}
			& li{
				color:#1E225C;
				display:flex;
				font-size:14px;
				gap:9px;
				justify-content: center;

				@include breakpoint(medium){
					justify-content:normal;
					font-size:15px;
				}
				
				img { 
					filter: invert(16%) sepia(41%) saturate(1875%) hue-rotate(211deg) brightness(84%) contrast(102%);
					width:16px;
				}
				& svg {
 					 position: relative;
					 transform: scale(0.7);
					 @include breakpoint(medium){
					 }
					 @include breakpoint(large){
						//top: .25em;
						//transform: unset;
					}
				}
				& svg path {
					fill:#1E225C;
				}
			}
		}
		
		
	}
}
.home-leader {
  background-repeat: repeat-x;
  background-position: center center;
  background-size: cover;
  height: 350px;
  position:relative;
  
  &__videobg { 
    /*position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
 	background: url('//demosthenes.info/assets/images/polina.jpg') no-repeat;
  	background-size: cover;
  	transition: 1s opacity;*/
  	position: absolute;
    top: -40px;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: 100%; 
    height: auto;
    z-index: -100;
    transform: translateX(-50%);
    background-size: cover;
    transition: 1s opacity;
    @include breakpoint(xlarge) {
    	top: -200px;
    }
  }

  &__overlay{
  	  width:100%;
  	  height:100%;
  	  //margin-left:50%;
      background:white;
      //border-radius: 30px 0px 0px;
      position: absolute;
      //bottom:0px;
      //left:15%;
      z-index: 1;
      background:rgba(32, 27, 102, 0.5);
  }
  
  &__container {
    height: 250px;
    padding-top: 60px;
    position:relative;
    z-index:2;
  }
  
  &__subtext {
  		@include raleway;
  		font-weight:600;
  		color:#fff; 
    	display:none;
    	text-align:center;
  }
  .inline-spacer { display:block; }

  &__buttons {
  	margin-top:4rem;
  	text-align:center;
	.cf-button.shadow {
		margin-right:0px;
		margin-bottom:10px;
	}  	
	@include breakpoint(400px) {
		.cf-button.shadow {
			margin-right:40px;
			margin-bottom:0px;
		}
		.inline-spacer { display:inline; }
  	}
	.cf-button__text{
		margin: -2px 15px 0 15px;
	}
	.vakgebieden-button{
		@include breakpoint(large){
			width:280px;

		}
	}
  }
  &__date {
  	font-weight:600;
  	color:$color-orange;
  }
  
	
  @include breakpoint(400px) {
    height: 300px;
  }

  @include breakpoint(medium) {
    height: 325px;
	
	&__overlay {
  	  //width:50%;
      //left:50%;
      //height: 250px;
    }
    
    &__container {
      height: 250px;
      padding-top: 60px;
    }
    
  }

  @include breakpoint(large) {
    height: 450px;
	
	&__overlay {
  	  //width:50%;
      //left:50%;
      //height: 270px;
    }
    
    &__container {
      height: 270px;
      padding-top: 90px;
      padding-left: 10px;
    }
    &__subtext {
    	display:block;
    	margin-top:4rem;
    }
    &__date{
    	margin-bottom: 0.5rem;
    }
  }

  @include breakpoint(xlarge) {
    height: 450px;
	
	&__overlay {
  	  //width:50%;
      //left:50%;
      //height: 270px;
    }
    
    &__container {
      height: 270px;
      padding-top: 100px;
      padding-left: 20px;
    }
  }

  @include breakpoint(xxlarge) {
    height: 450px;
	
	&__overlay {
  	  //width:50%;
      //left:50%;
      //height: 270px;
    }
    
    &__container {
      height: 270px;
      padding-top: 100px;
      padding-left: 20px;

    }
  }
  &--404{
  	height:100px;
  	@include breakpoint(medium) {
  	height:200px;
  	}
  	@include breakpoint(large) {
  	height:200px;
  	}
  	@include breakpoint(xlarge) {
  	height:200px;
  	}
  	@include breakpoint(xxlarge) {
  	height:200px;
  	}
  }
  
  &__title {
    @include raleway;
    font-weight: 600;
    /*color: $color-header;*/
    color: #fff;
    /*text-shadow: 1px 1px 0 #fff;*/
    float: left;
    width: 100%;
    font-size: rem-calc(26);
    line-height: 1.1;
    padding: 0;
    padding-bottom:15px;
    text-align:center;
	

    @include breakpoint(360px) {
      font-size: rem-calc(26);
    }

    @include breakpoint(420px) {
      font-size: rem-calc(26);
    }

    @include breakpoint(medium) {
      font-size: rem-calc(30);
    	
    }
    @include breakpoint(large) {
      font-size: rem-calc(36);
      max-width: 1200px;
      padding-bottom:0px;
    }
    @include breakpoint(xlarge) {
      font-size: rem-calc(36);
    }

    a {
      text-decoration: none;
      color: white;
      text-shadow: 1px 1px 1px $color-header;
    }

    strong {
      font-size: 140%;
    }

    .cf-button {
      text-shadow: none;
      font-size: 80%;
      height: rem-calc(30px);
      line-height: 1.5;
      margin: 8px auto 0;

      @include breakpoint(medium) {
        margin: 15px auto 0;
        width: rem-calc(320px);
        height: rem-calc(40px);
      }

      @include breakpoint(large) {
        margin: 15px 0 0;
        width: auto;
        float: left;
        clear: left;
        height: rem-calc(50px);
      }
    }
  }

  &__action {
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: rem-calc(24px);
  }

  &__button {
    width: 280px;
    position: absolute;
    top: 250px;
    left: 350px;
  }
}
.homepagevideo{
	background: rgb(255,255,255);
	background: linear-gradient(180deg, rgba(255,255,255,1) 30%, rgba(248,248,250,1) 30%);

	#homepage-background-image{
		z-index: 2;
		position: inherit;
	}
	.homepage-background-image-class{
		z-index: 2;
		position: inherit;
		//height: 200px;
		width: 100%;
		//object-fit: cover;
		border-radius: 20px !important;
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
  		height: 0;
		background-size: cover;
		background-position: bottom;
		@include breakpoint(medium) {
			//height: 650px;
		}
		@include breakpoint(large){
			background-position: 90%;
		}
		.videotext-block__video__container {
			padding-bottom: 56.25%; /* 16:9 */
		}
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%!important;
			height: 100%!important;
			border-radius: 8px;
		}
	}
	.vid-btn{
		z-index: 3;
	}
}
.cursistenreview{
	padding-top:50px;
	background-color: #F8F8FA;
	@include breakpoint(medium up) {
		padding-top:120px;
		padding-bottom:120px;
	}

	.uitlijncuristreview-home{
		//max-width: 100rem;
		margin:auto;
		display:grid;
		@include breakpoint(medium up) {
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr;
		}
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 1fr;
		padding-left: 2rem;
		padding-bottom: 50px;
	}
	&__reviewbericht{
		padding-left: 0.9375rem;
		margin-right: 40px;
		@include breakpoint(large) {
			margin-right: 80px;
		}
		&__titel{
			color: $color-business-blue;
			font-size:25px;
			font-weight: 800;
			font-family:"Lexend",sans-serif;

			@include breakpoint(large) {
				font-size: rem-calc(21px);
			}
		}
		&__message{
			color: $color-business-blue;
			margin-bottom: 0;
			font-size:16px;
		}
		&__leesmeer{
			text-decoration: underline;
			font-weight:500;
			display: inline-block;
			margin-top: 10px;
			margin-bottom: 20px;
			@include breakpoint(large) {
				margin-top: 0px;
			}
			p{
				font-family:"Lexend",sans-serif;
				color: $color-business-blue;
				font-size:16px;
			}
		}
		&__review-company{
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;

			&__imageblock{
				display: grid;
				justify-items: center;
				@include breakpoint(medium up) {
					margin-right: 30px;
				}
				&__star{
					svg{
						color:#F5C80B;
						font-size: 10px;
						@include breakpoint(large) {
							font-size: 16px;
						}
					}
				}
				&__image{
					padding:0;
					mix-blend-mode: luminosity;
				}
				span{
					color:$color-business-blue;
					font-weight: 600;
					font-family:"Lexend",sans-serif;
					margin: 0;
					font-size: 16px;
					margin-bottom: 20px;
					@include breakpoint(large) {
						margin-top:15px;
						font-size:20px;
						margin-bottom:0;
					}
					
				}
			}
		}
	}
	&__photocarousel{
		max-height: 360px;
		max-width: 100vw;
		min-height:320px !important;
		@include breakpoint(medium up) {
			position: absolute !important;
			right: 0;
			min-height:320px !important;
			max-width: 50vw;
		}
		.slick-list{

		}
		.prev-arrow{
			right: 60px !important;

			@include breakpoint(medium up){
				right: 75px !important;
			}
		}
		.slide-arrow{
			margin-right: 40px;
			height: 45px !important;
			padding: 6px;
			bottom: -60px;
			width: 50px !important;

			@include breakpoint(medium up){
				height: 50px !important;
				padding: 8px;
				width: 60px !important;
			}
		}
		.slider__counter{
			bottom: -50px;
			top:auto;		
			@include breakpoint(medium up){
				margin-right: 40px;
			}
		}
		&__imgbox{
			height:100%;
			margin-right: 30px;
			@include breakpoint(medium up) {
				margin-left: 20px;
				margin-right:0;
			}
			img{
				height: 100%;
				object-fit: cover;
				border-radius: 12px;
			}
		}
		.slick-list{
			height:100%;
			background: #f8f8fa;
			.slick-track{
				height:100%;
			}
		}
		//@include breakpoint(medium up) {
		//	height:30vh;
		//}
		//.slick-list{
		//	height: 100%;
		//	width: 100%;
        ///
		////	.slick-track{
		//		height: 100%;
		//		.slick-slide {
		//			margin-left:20px;
		//			width:30vw !important;
		//		}
		//	}
		//}
	}
}
.clustersection{
	margin: 50px 0px!important;
	margin-bottom:50px!important;
	@include breakpoint(large){
		margin: 125px 0px!important;
		margin-bottom:125px!important;
		padding-bottom:0px!important;

	}
}
.home-content-divider{
	&--grey{
		border-bottom: 1px solid #F8F8FA !important;

		&::after{
			border-top: 30px solid #F8F8FA !important;
		}
	}
	
}
.cijfers-meeroverons{
	background-color:#F8F8FA;
	padding-top:45px;
	margin:0;
	@include breakpoint(medium) {
		padding-top:70px;
	}
	.hr-container{
		padding-left:1.0625rem;
		padding-right:1.0625rem;
	}
	hr{
		margin:0;
		margin-top:45px;
		width: 100%;
		@include breakpoint(large) {
			margin-top:30px;

		}
	}
	&__vak{
		display:grid;
		grid-template-columns:none;
		grid-template-rows: 1fr 1fr 1fr 1fr;
		align-items: center;
		row-gap: 35px;
		@include breakpoint(medium) {
			grid-template-columns: 1fr 1fr 1fr 1fr;
			grid-template-rows: none;
			row-gap: 0px;
		}
		@include breakpoint(large){
			padding-left:1.0625rem;
			padding-right:1.0625rem;
		}
		&__titel{
			font-size: 25px;
			@include header-text;
			font-weight: 900;
			margin-bottom: 0;
			line-height: inherit;
			text-align: center;
			@include breakpoint(medium) {
				text-align: start;
				font-size: 45px;
			}

		}
		&__ondertekst{
			font-weight: 500;
			margin:0;
			text-align: center;
			color: rgba(30,34,92,.5);
			@include breakpoint(medium) {
				text-align: start;
				color: #1e225d;
			}
		}

		a{
			margin: auto;
			width: max-content;
			font-weight:600;
			line-height: 1;
			@include breakpoint(medium) {
				padding: 15px 35px;
				float:right;
				width: max-content;
			}
			@include breakpoint(large){
				.cf-button__text{
					width: max-content!important;
				}
			}
		}
	}
}
.uitgelichte_trainingen{
	background-color:#F8F8FA;
	padding-top:50px;
	padding-bottom:50px;
	@include breakpoint(large){
		padding-top:125px;
		padding-bottom:125px;
	}
	.row{
		margin-right:2rem;
		margin-left:2rem;

		@include breakpoint(large) {
			margin-right:auto;
			margin-left:auto;
		}
	}

	&__title{
		font-size:20px;
		font-weight:600;
		padding:0px;
		@include header-text;
		@include breakpoint(medium) {
			padding:inherit;
			font-size:28px;
			font-weight:600;
		}
		@include breakpoint(large){
			padding-left:2rem;
			padding-right:2rem;
		}
		span{
			color: #77799c;
		}
		p, h2{
			margin-bottom:25px;
			@include header-text;
		}
	}
	.training-grid-block{
		padding:10px;
	}
	.rf-cards-scroller-crop {
		height: 38.5rem;
		@include breakpoint(medium){
			height: 31.5rem;
		}
		@include breakpoint(large){
			height:33.5rem;

		}
	}

	.rf-ccard-40 .rf-ccard-content {
		width: 23rem;
		@include breakpoint(large){
			width: 25rem;
		}
	}
}
.bekijkwatwedoen{
	margin-bottom:50px;
	@include breakpoint(large){
		margin-bottom:125px;
		margin-top:125px;
	}
	.row{
		margin-right:2rem;
		margin-left:2rem;

		@include breakpoint(large) {
			margin-right:auto;
			margin-left:auto;
		}
	}
	&__title{
		font-size:20px;
		font-weight:600;
		padding:0px;
		@include header-text;
		@include breakpoint(medium) {
			font-size:28px;
			font-weight:600;
		}
		@include breakpoint(large){
			padding-left:2rem;
			padding-right:2rem;
		}
		span{
			color: #77799c;
		}
		p{
			margin-bottom:25px;
			@include header-text;
			@include breakpoint(large){
				margin-bottom:35px;
			}
		}
	}
	&__slider{
		height: 200px;
		width: auto;
		margin-bottom: 20px;
		@include breakpoint(medium) {
			height: 600px;
			margin-bottom:auto;
			margin-bottom: 50px;
		}

		&__card{
			//width: 90%;
			height: 100%;
			background-size: cover;
			border-radius: 8px;

			.videotext-block__video__container2{
				width:100%; 
				height:100%; 
				position:relative;

				.vid-icon{
					font-size: 60px;
					color: #fff;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%) !important;
					text-align: center;
					padding: 20px;
					background-color: rgba(255, 255, 255, 0.3);
					border-radius: 100%;
					cursor: pointer;
					-webkit-backdrop-filter: blur(10.7px);
					backdrop-filter: blur(10px);
					transition: margin 400ms, box-shadow 400ms;
					width: 25%;

					img{
						display: block;
						@include breakpoint(medium) {
							display: inline-block;
						}
					}
					@include breakpoint(medium) {
						width: auto;
					}
				}
				iframe{
					border-radius: 8px;
				}
			}
			.videotext-block__video__container3{
				width:100%; 
				height:100%; 
				position:relative;

				.vid-icon{
					font-size: 60px;
					color: #fff;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%) !important;
					text-align: center;
					padding: 20px;
					background-color: rgba(255, 255, 255, 0.3);
					border-radius: 100%;
					cursor: pointer;
					-webkit-backdrop-filter: blur(10.7px);
					backdrop-filter: blur(10px);
					transition: margin 400ms, box-shadow 400ms;
					width: 25%;

					img{
						display: block;
						@include breakpoint(medium) {
							display: inline-block;
						}
					}
					@include breakpoint(medium) {
						width: auto;
					}
				}

				iframe{
					aspect-ratio: 16 / 9; 
					width: 100%;
				}
			}
		}
	}

	.snap-slider .rf-cards-scroller-crop{
		height:16rem;
		@include breakpoint(large){
			height: 39rem;
		}
	}
	.snap-slider  .rf-ccard-40 .rf-ccard-content {
		width: 23rem;
		height: 15rem;
		@include breakpoint(large){
			width: 65rem;
			height: 36rem;
		}
	}
	.snap-slider .rf-ccard-img-full-wrapper img{
		//@include breakpoint(large){
			//height:max-content;
			object-fit: cover;
			height: 100%;
			width: 100%;
		//}
	}
}
.testimonial-block-home{
	background-color:#F8F8FA;
	padding-top:50px;
	padding-bottom: 50px;
	@include breakpoint(large){
		padding-top:125px;
		padding-bottom: 125px;

	}
	.row{
		margin-right:2rem;
		margin-left:2rem;

		@include breakpoint(large) {
			margin-right:auto;
			margin-left:auto;
		}
	}

	&__title{
		font-size:20px;
		font-weight:600;
		padding:0px;
		@include header-text;
		@include breakpoint(medium) {
			font-size:28px;
			font-weight:600;
		}
		@include breakpoint(large){
			padding-left:2rem;
			padding-right:2rem;
		}
		p{
			margin-bottom:25px;
			font-weight:600;
			padding:0px;
			@include header-text;
			@include breakpoint(medium) {
				font-size:28px;
				font-weight:600;
			}
			@include breakpoint(large){
				margin-bottom:35px;
			}
		}
	}
	&__slider{
		height:500px;
		display:flex;
		column-gap: 30px;
		&__card{
			height: 100%;
			background-color:white;
			//width: 90%;
			@include breakpoint(medium) {
				//width: 33%;
			}
			border-radius: 8px;
			display: grid;
			grid-template-rows: auto auto 300px;
			&__top{
				padding:30px;
				padding-bottom:0px;
				//display: grid;
				grid-template-columns: 1fr 3fr 1fr;
				align-items: center;
				border-bottom: 1px solid #f3f4f6;
				&__foto{
					margin: 15px;
					img{
						border-radius: 100%;
						border: 1px solid $color-business-blue;
					}
				}

				&__naam{
					display:grid;
					color:$color-business-blue;
					strong{
						font-size:18px;
						font-weight:700!important;
						@include breakpoint(large){
							font-size:22px;
						}
					}
				}
				&__logo{
					position: relative;
					height: 100%;

					img{
						position:absolute;
						right: 10px;
						top: 10px;
					}
				}
			}
			&__middle{
				display: flex;
				flex-direction: column;
				margin-right: 30px;
				margin-left: 30px;
				margin-top: 15px;
				margin-bottom:15px;
				span{
					color:$color-business-blue;
					font-size:15px;
					display: flex;
					align-items: center;
					gap:10px;
					&.grade{
						//color:#FFC107;
						.fas{
							color:#FFC107;
						}
						svg path { fill:#FFC107; }
					}
					
				}
				p{
					margin-top:10px;
				}
			}
			&__body{
				padding:30px;
				padding-top:0px;
				overflow: scroll;
			}
		}

	}
	.snap-slider .rf-ccard-40 .rf-ccard-content {
		width: 23rem;
		height: 370px;
		@include breakpoint(large){
			width: 25rem;
			height: 440px;
		}
	}
	.rf-cards-scroller-crop {
		@include breakpoint(medium){
			height: 25rem;
		}
		@include breakpoint(large){
			height: 30rem;
		}

	}
}
.nieuws-inspiratie{
	margin-top:50px;
	margin-bottom: 50px;
	@include breakpoint(large){
		margin-top:125px;
		margin-bottom: 125px;

	}

	.row{
		margin-right:2rem;
		margin-left:2rem;

		@include breakpoint(large) {
			margin-right:auto;
			margin-left:auto;
		}
	}

	&__title{
		font-size:20px;
		font-weight:600;
		padding:0px;
		@include header-text;
		@include breakpoint(medium) {
			font-size:28px;
			font-weight:600;
		}
		@include breakpoint(large){
			padding-left:2rem;
			padding-right:2rem;
			margin-bottom:35px;
			p{ margin-bottom:0px;}
		}
	}
	.rf-cards-scroller-crop{
		height:36rem;
		@include breakpoint(large){
			height:34rem;
		}
	}
	.rf-ccard-40 .rf-ccard-content {
		width: 23rem;
		height: 470px;
		@include breakpoint(large){
			height: 520px;
			width: 25rem;
		}
	}
		.post-summary-large{
			padding:10px;
			border-radius: 2px 16px 16px 16px;
		}
		.post-summary-large__date{
			color:$color-business-blue;
			font-weight: 500;
			font-family: 'lexend', 'sans-serif';
		}
		.post-summary-large__title{
			min-height: 35px;
		}
		.post-summary-large__image img{
			border-radius:8px;
			@include breakpoint(large){
				max-height:220px;
				object-fit: cover;
			}
		}
		.post-summary-large__content {
			max-height:280px;
			text-overflow: ellipsis;
			overflow: hidden;
			@include breakpoint(large){
				max-height:250px;
				text-overflow: ellipsis;
				padding:25px;
				
			}
		}
}
.magazine-pro-home{
	margin-top:100px;
	position:relative;
	margin-bottom: 100px;
	@include breakpoint(medium){
		margin-top:200px;
		margin-bottom:150px;
	}
	.row{
		position:relative;
		padding-left:2rem;
		padding-right: 2rem;

		&::before{
			content: "";
			background-image: url(/images/dot-bgv5.svg);
			height: 50%;
			width: 50%;
			position: absolute;
			background-repeat: repeat;
			background-position: center;
			background-size: 45px;
			bottom: -50px;
			left:-15px;

			@include breakpoint(medium){
				height: 75%;
				width: 30%;
			}
		}
		&::after{
			content: "";
			background-image: url(/images/dot-bgv5.svg);
			height: 50%;
			width: 50%;
			position: absolute;
			background-repeat: repeat;
			background-position: center;
			background-size: 45px;
			top: -50px;
			right:0px;

			@include breakpoint(medium){
				height: 75%;
				width: 30%;
			}
		}
	}
	&.nobackgroundpoints{
		.row{
			&::before{
				background-image: none;
			}
			&::after{
				background-image: none;
			}
		}
	}
	.column{
		display:grid;
		grid-template-rows: 1fr 1fr;
		border: 1px solid #1E225D;
		border-radius: 8px;
		padding-top:20px;
		padding-bottom: 20px;
		position: relative;
		background: white;
		z-index: 2;

		@include breakpoint(medium){
			grid-template-columns: 7fr 8fr;
			grid-template-rows:none;
			padding-top:100px;
			padding-bottom: 100px;
		}
	}
	&__imgbox{
		width: 100%;
		height: 100%;
		position: relative;

		img{
			position: absolute;
			height: 80%;
			width: initial;
			margin-left: auto;
			margin-right: auto;
			left: 0;
			right: 0;
			top: -40px;
			transform: rotate(-7deg);

			@include breakpoint(medium){
				margin-right: 30px;
			}
			@include breakpoint(large){
				top: -140px;
				height: 150%;
				margin-right: auto;
			}
			@media only screen and (max-width: 1110px) and (min-width: 1000px) {
				margin-right: 30px;
			}
		}
	}
	&__textbox{
		padding-left: 0;
		margin-top: -50px;
		@include breakpoint(medium){
			margin:0;
		}
		&__title{
			font-size:22px;
			font-weight: 800;
			color:$color-business-blue;
			font-family: 'Lexend', sans-serif;
			margin-left: 20px;
			@include breakpoint(medium){
				font-size:35px;
				margin-left: 0px;
			}
		}

		&__text{
			font-size:15px;
			color:$color-business-blue;
			margin-right: 30px;
			line-height: 1.6;
			margin-left: 20px;
			@include breakpoint(medium){
				margin-left: 0px;
				margin-right: 130px;
			}
		}
		&__button{
			margin-top: 20px;
			//padding: 20px 40px !important;
			margin-left: 20px;
			margin-right: 20px;
			width: auto;
			@include breakpoint(medium){
				margin-left:0;
				margin-right:0;
			}
		}
	}
	&--greybackground{
		padding:50px 0px;
		margin:50px 0px;
		background-color: #F8F8FA;
		@include breakpoint(large){
			padding:100px 0px;
			margin:100px 0px;
		}
		form label {
			background-color: #F8F8FA!important;
		}
		form input {
			background-color:transparent!important;
		}
	}
}
.aanvraag-TMA-test{
	.row{
		display:block;
		@include breakpoint(large){
			display:grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: none;
		}
	}
	position:relative;
	.img-position{
		height: 600px;
		position: absolute;
		bottom: 0;
		left: 60%;
		right: 40%;
	}

	.mobile-img-position{
		display:grid;
		justify-content: center;
		img{
			height: 500px;
		}
	}

	.form-contact{
		display: grid !important;
		grid-template-areas:
	  "naam           naam          " 
	  "email          email         " 
	  "telefoonnummer telefoonnummer" 
	  "verstuur       verstuur      " !important;
	}
}
.podcast{
	.home-leader-new__title{
		margin-bottom:0px;
	}
	.podcast_introduction_tekst{
		max-width: 800px;
		text-align: center;
		margin-top:30px;
	}
	.podcast__banner__img{
		background-image:url(https://www.competencefactory.nl/wordpress/wp-content/uploads/2024/08/coverphoto-podcast.png); 
		background-size: contain; 
		background-repeat: no-repeat; 
		background-position: center;
		border-radius: 20px;
	}
	.podcast_introduction_link{
		text-align:center;
		a{
			text-decoration: none;
			img{
				width:24px;
				height:24px;
				filter: invert(16%) sepia(41%) saturate(1875%) hue-rotate(211deg) brightness(84%) contrast(102%);
			}
		}
	}
	&__item{
		background-color: white!important;
		&__container{
			display: flex;
			flex-direction: column;
			gap:20px;
			@include breakpoint(large){
				flex-direction: row;
				align-items: center;
				height:100%;
			}
		}
		&__image{
			@include breakpoint(large){
			min-width: 300px;
			max-width: 300px;
			align-self: stretch;
			}
			img{
				margin-top:15px;
				border-radius: 2px 16px 16px 16px;
				width: 100%;
				height:200px;
				@include breakpoint(large){
					margin-top:0px;
					width:auto;
					height:100%;
				}
				object-fit: cover;

			}
		}
		&__content{
			&__title{
				font-size: 22px;
        		font-weight: 600;
    			font-family: 'Lexend', sans-serif;
			}
			&__cta{
				margin-bottom: 0px;
				display: flex;
				flex-direction: column;
				gap:20px;
				@include breakpoint(large){
					align-items: center;
					flex-direction: row;

				}
			}

		}
	}
	.podcast__container{
		padding-bottom: 100px;
		display: grid;
		grid-template-columns: 1fr;
		gap: 50px;
		padding-left: 1rem;
    	padding-right: 1rem;
		@include breakpoint(medium){
			grid-template-columns: 1fr 1fr;
			gap: 50px;
		}
		@include breakpoint(large){
			grid-template-columns: 1fr 1fr 1fr 1fr;
			gap: 50px;
		}
		&__item{
  			width: 100%;
			position:relative;
			text-decoration: none;
			&__img{
				height: 300px;
				border-radius: 20px;
				background-size: cover;
				position: relative;
				@include breakpoint(medium){
					height: 250px;
				}
			}
			&__overlay{
				z-index: 5;
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				border-radius: 20px;

				#vid-icon{
					display:none;
					color: #fff;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					text-align: center;
					padding: 20px;
					background-color: rgba(255, 255, 255, 0.3);
					border-radius: 100%;
					cursor: pointer;
					-webkit-backdrop-filter: blur(10.7px);
					backdrop-filter: blur(10px);
					transition: margin 400ms, box-shadow 400ms, transform 400ms;
				}
			}
			&__title{
				font-size: 18px;
        		font-weight: 600;
				padding: 0px;
				margin-top:5px;
    			font-family: 'Lexend', sans-serif;
			}
			&:hover{
				.podcast__container__item__overlay{
					background-color: rgba(32, 42, 54, 0.5);
					transition: all 400ms;

					#vid-icon{
						display:block;
					}
				}
			} 
		}
	}
	.podcast_review{
		background:white;

		.podcast_review_card{
			.testimonial-block-home__slider__card__top__naam{
				font-size: 22px;
  				font-weight: 700;
			}
		}
	}
	.podcast_volgvoormeer{
		display:flex;
		&__title{
			line-height: 1.3;
		}
		&__usp{
			list-style-type: none;
			margin-left: 0;

			li{
				img{
					filter: invert(16%) sepia(41%) saturate(1875%) hue-rotate(211deg) brightness(84%) contrast(102%);
					margin-right: 5px;
				}
			}
		}
		&__links{
			justify-content: start !important;
			a{
				display: grid !important; 
				width: 270px;
  				grid-template-columns: 8fr 2fr;

				.cf-button__text{
					width: fit-content;
				}
				.cf-button__icon{
					display: flex;
					justify-content: flex-end;

					svg{
						filter: invert(18%) sepia(11%) saturate(3629%) hue-rotate(198deg) brightness(94%) contrast(107%);
					}
				}
				&:hover{
					background-color:#1e225d;
					border-color: #1e225d;
					transition: all 400ms;
					.cf-button__text{
						color: white;
					}
					.cf-button__icon{
						img{
							filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
							
						}
						svg{
							filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%);
						}
					}
				}
			}
		}
	}
	.podcasts_title{
		padding-top: 50px;
  		text-align: center;
	}
}
.upload_lesmateriaal{
	&__box{
		margin-bottom:30px;

		&__icons{
			display:flex;

			&__svg{
				cursor: pointer;
			}
			&__span{
				cursor: pointer;
			}
		}
		&__upload{
			margin:0 0 1rem;
			.LesmateriaalUpload{
				margin: 0;
				cursor: pointer;
				color: #1E225C;
				&::file-selector-button{
					color: #1E225C;
					background-color:white;
					border:1px solid #1E225C;
					letter-spacing: -0.1px;
					padding:5px 10px;
					border-radius: 5px;
	
					&:hover{
						color: white;
						background-color:#1E225C;
					}
				}
			}
			&__span{
				font-size:12px;
			}
		}

		&__label{
			color: #1E225C;
		}
	}
	.progressWrapper{
		width: 100%;
		background-color: #f3f3f3;
		border-radius: 10px;
		height: 15px;
		display: none;
		margin-top:0px;
		margin-bottom:10px;
	}
	.progressBar {
		height: 100%;
		width: 0;
		background-color: #4caf50;
		border-radius: 10px;
		text-align: center;
		color: white;
		line-height: 15px;
	}
}