
.mainmenu-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid $color-line;
  box-shadow: 0 3px 10px rgba(0,0,0,0.1);
  padding: 0 0 15px;
  z-index: 9999999;
  @include header-text;
}

.no-js .mainmenu-mobile {
  display: none;
}

.mainmenu-desktop {
  z-index: 1;
  display: inline-block; 
  .menu {
    width: auto;
    //flex-wrap: wrap;
  }
  @media print {
	display:none!important;
  }
  &__menu-item {
    @include custom-button-base;
    float: left;
    height: 50px;
    font-weight:400;
    @include breakpoint(1050px){
    	font-size:14px;
    }
    @include breakpoint(1300px){
    	font-size: 15px;
    }
  img{
    width:15px;
    height:15px;
    object-fit: contain;
    filter: invert(12%) sepia(43%) saturate(3297%) hue-rotate(224deg) brightness(85%) contrast(95%);
		margin-left:5px;
  }
	svg {
		position:relative;
		top:-0.125rem;
		transform:scale(0.55);
		margin-left:5px;
	}
    &:hover {
      text-decoration:underline;
    }

    a {
      text-decoration: none;
      color: $color-blue;
      @include header-text;
    }

    &--trainingen {
      font-weight: 400;
    }

    &--aanbiedingen {
      font-weight: 400;

    }
  }

  .menu > li {
    & > a {
      padding: 0.9rem 0.35rem;
      line-height:1.5;
      align-items:center;
      @include breakpoint(1300px){
        padding: 0.9rem 0.7rem;
      }
    }
  }
}