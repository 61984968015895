.inspiration-block{
	background-color:#F8F8FA;
	padding:85px 0px;
	&__main{
		margin-bottom:35px;
		padding:0 2rem;
		@include breakpoint(large){
			padding:inherit;
		}
		&__title{
			font-size:rem-calc(25);
			font-weight:800;
			@include header-text;
			@include breakpoint(medium){
				font-size:rem-calc(39);
				text-align:center;
			}
			@include breakpoint(large){
				width:70%;
				margin:0 auto;
			}
		
		}
		&__subtitle{
			margin-top:15px;
			font-size:rem-calc(18);
			@include breakpoint(large){
				text-align:center;
				width:70%;
				margin-left:auto;
				margin-right:auto;
			}
		
		}
	}
	&__section{
		& > div.row:not(.section-content):first-of-type > div.small-12, & > div.row:not(.section-content):last-of-type > div.small-12{
			padding:0 2rem;
			@include breakpoint(medium){
				padding:0 0.9375rem;
			}
		}
		&__title{
			font-size:rem-calc(20);
			font-weight:800;
			@include header-text;
			text-align:left;
			margin-bottom:35px;
			@include breakpoint(medium){
				font-size:rem-calc(30);
			}
		}
		&__button{
			margin-top:35px;
			text-align:right;
		}
		margin-bottom:80px;
		&:last-of-type{
			margin-bottom:0px;
		}
		
		.slide-box{
			margin-bottom:20px;
			margin-right:0px;
			box-shadow: 0px 15px 40px rgba(42,62,82,0.129);
			@include breakpoint(medium){
				margin-bottom:0px;
			
			}
		}
		a.blog-grid-item{
			text-decoration:none;
			
		}
		.slide-box:hover{
			a.link svg{
				margin-left:20px;
			}
		}
	}
	&__item{
		position:relative;
		box-shadow: 0px 15px 40px rgba(42,62,82,0.129);
		border-radius: 8px;
		background:white;
		margin:0px!important;
		&__image{
			position:relative;
			padding:1.2rem;
			@include breakpoint(medium){
				padding:0.8rem;
			}
			#vid-icon{
				position: absolute;
				left: 50%;
				top: 50%;
				
				padding:20px;
				background-color: rgba(255, 255, 255, 0.3);
				border-radius: 100%;
				cursor: pointer; 
				-webkit-backdrop-filter: blur(10.7px);
				backdrop-filter: blur(10px);
				margin-left: -75px;
    			margin-top: -75px;
				&:hover{
					box-shadow: 0px 12px 40px 0px rgba(157, 171, 192, 0.5);
				} 
			}
			&__background{
				min-height:180px;
				border-radius: 4px 4px 4px 4px;
				background-size:cover;
				background-repeat:no-repeat;
				background-position:center center;
				height:100%;
				width:100%;
				@include breakpoint(medium){
					border-radius: 4px 4px 4px 70px;
					min-height:450px;
					&.smaller{
						min-height:350px;
					}
				}
				iframe {
					width:100%;
					height:219px;
					@include breakpoint(large){
						height:526px;
					}
				}
				
			}
			& > a > img{
				border-radius: 4px 4px 4px 70px;
				object-fit: cover;
			}
		}
		&__text{
			padding:1.2rem;
 			@include breakpoint(medium){
				padding:30px;
 			}
 			@include breakpoint(large){
				padding:50px;
 			}

			&__label{
				background-color:#FFF500;
				border-radius:100px;
				color:$color-business-blue;
				font-weight:600;
				display:inline-block;
				padding:3px 10px;
				@include header-text;
				margin-bottom:20px;
			}
			&__title{
				font-size:rem-calc(25);
				@include header-text;
				margin-top: 10px;
				margin-bottom: 10px;
				
			}
			&__subtitle{
				opacity: 0.53;
				font-size:rem-calc(20);
				@include header-text;
				margin-top:10px;
			}
			&__description{
				margin-top:20px;
				margin-bottom:20px;
				@include breakpoint(medium){
					font-size:16px;
				}
			
			}
			.cf-button--blue-flat-alt{
				@include breakpoint(medium){
					margin-top: 20px;
				}
			}
			
		}
		&__favorite {
			border-radius:100%;
			border: 1px solid $color-business-blue;
			width:50px;
			height:50px;
			background-color:white;
			padding:13px 11px 10px 11px;
			position:absolute;
			margin-top:10px;
			right:25px;
			bottom:-8px;
			z-index:4;
			box-shadow: 0px 10px 30px 0px rgba(157, 171, 192, 0.2);
			@include breakpoint(medium){
				top:40px;
				left:-11px;
			}
			&.selected{
				&.collection{
					padding:5px 5px 5px 5px;
				}
				img.selected{
					filter: invert(36%) sepia(79%) saturate(1372%) hue-rotate(328deg) brightness(112%) contrast(107%);
					display:block;
					margin:1px!important;
				}
				img.notselected{
					display:none;
					margin:1px!important;
				}
			}
			&.notselected{
				img.selected{
					display:none;
					margin:1px!important;
				}
				img.notselected{
					filter: invert(9%) sepia(69%) saturate(2738%) hue-rotate(228deg) brightness(99%) contrast(93%);
					display:block;
					margin:1px!important;
				}
			}
			&:hover{
				cursor:pointer;
				&.notselected{
					img.notselected{
						filter: invert(36%) sepia(79%) saturate(1372%) hue-rotate(328deg) brightness(112%) contrast(107%);
						transition:(all 400ms);
					}
				}
			}
		  }
		&--seo-landingpage{
			.inspiration-block__item{
				&__text{
					& > div{
						margin-left: 0px;
						margin-top: 0px;
						margin-bottom: 0px;
					}
					padding:1.2rem;
					@include breakpoint(medium){
						padding:20px;
					}
					&__description{
						margin-top:20px;
						margin-bottom:20px;
			
					}
				}
				
			}
		}
	}
}